import React, {useEffect, useRef, useState, useMemo} from "react"
import ProductVariantOption from "../ProductVariantOption"
import DotList from "../DotList"
import { alphabeticalSort } from "../../utils/stringUtils"
import styles from "./ProductVariantPicker.module.css"

const ProductVariantPicker = ({variantsById={}, selectedVariantId="", defaultImageUrl="", onChange=()=>{}, editable=true, highlightVariantIdMap={}}) => {
    const selectedVariant = typeof variantsById !== 'object' ? null :
                            variantsById[selectedVariantId]
    const selectedVariantIsinactive = selectedVariant ? selectedVariant.isInactive : false
    const selectedVariantRef = useRef(null); // Ref to track the selected variant
    const containerRef = useRef(null); // Ref for the scrolling container
    const [hasScrollBar, setHasScrollBar] = useState(false);
    useEffect(() => {
        //if the selected variant is deleted or inactive, switch to the next active variant
        if (!selectedVariant || (selectedVariant && selectedVariantIsinactive)){
            const nextActiveVariant = Object.values(variantsById).find(v => !v.isInactive)
            if (nextActiveVariant) onChange(nextActiveVariant.id)
        }
    }, [selectedVariant, selectedVariantIsinactive])

    useEffect(() => {
        // Scroll the selected variant into view on mount or when it changes
        if (selectedVariantRef.current && containerRef.current) {
            const container = containerRef.current;
            const variant = selectedVariantRef.current;

            const containerRect = container.getBoundingClientRect();
            const variantRect = variant.getBoundingClientRect();

            const variantLeft = variantRect.left - containerRect.left;
            const variantRight = variantRect.right - containerRect.right;
            
            if (variantLeft < 0) {
                // If the selected variant is out of view on the left, scroll left
                container.scrollLeft += variantLeft;
            } else if (variantRight > 0) {
                // If the selected variant is out of view on the right, scroll right
                container.scrollLeft +=  variantRight;
            }
        }
    }, [selectedVariantId]);
    //detect whether the container has a scrollbar. Re-run when the window size changes
    useEffect(() => {
        const checkForScrollbar = () => {
            if (containerRef.current) {
                setHasScrollBar(containerRef.current.scrollWidth > containerRef.current.clientWidth);
            }
        };

        checkForScrollbar();
        window.addEventListener("resize", checkForScrollbar);
        
        return () => window.removeEventListener("resize", checkForScrollbar);
    }, []);
    const variantList = useMemo(() => Object.values(variantsById || {})
                              .sort((v1, v2) => alphabeticalSort(String(v1.variantDetails), String(v2.variantDetails)))  
                              .sort((v1, v2) => v1.price - v2.price) 
                              , [variantsById])
    const selectedVariantIndex = useMemo(() => variantList.findIndex(v => v.id === selectedVariantId ), [selectedVariantId, variantList])
    if (typeof variantsById !== 'object') return null
    return (
        <div>
            <div  ref={containerRef} className={styles.container}>
                {
                    variantList
                        .map(v => {
                            const {price=0, variantDetails="", images=[], isInactive=false} = v || {}
                            const imageUrl = images && images.length >= 1 && typeof images[0] === 'object' ? 
                                            images[0].small ? images[0].small : images[0].med ? images[0].med : defaultImageUrl
                                            :
                                            defaultImageUrl
                                            
                            return (
                                <div 
                                    key={v.id} 
                                    className={variantList.length > 1 ? "margin-right-05em" : ""}
                                    ref={selectedVariantId === v.id ? selectedVariantRef : null} // Attach ref to the selected variant
                                    style={{ minWidth: "80px", maxWidth: "120px", flexShrink: 0 }} 
                                >
                                    <ProductVariantOption 
                                        price={price}
                                        variantDetails={variantDetails}
                                        imageUrl={imageUrl}
                                        selected={selectedVariantId === v.id}
                                        highlighted={Boolean(highlightVariantIdMap[v.id])}
                                        isInactive={isInactive}
                                        onClick={() => onChange(v.id)}
                                        editable={editable}
                                    />
                                </div>
                            )

                        })
                }
            </div>
            {hasScrollBar && (
                <DotList count={variantList.length} selectedIndex={selectedVariantIndex}/>
            )}
        </div>
    )
}

export default ProductVariantPicker