import React from "react";
import {logError} from "../../utils/errorHandlingUtils"

import AccountCircle from '@mui/icons-material/AccountCircle'
import Add from '@mui/icons-material/Add'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import Close from '@mui/icons-material/Close'
import ContentCopy from '@mui/icons-material/ContentCopy'
import Done from '@mui/icons-material/Done'
import DonutSmall from '@mui/icons-material/DonutSmall'
import Download from '@mui/icons-material/Download'
import Email from '@mui/icons-material/Email'
import Home from '@mui/icons-material/Home'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import LocalMall from '@mui/icons-material/LocalMall'
import Logout from '@mui/icons-material/Logout'
import MonetizationOn from '@mui/icons-material/MonetizationOn'
import MoreVert from '@mui/icons-material/MoreVert'
import OpenInNew from '@mui/icons-material/OpenInNew'
import Payment from '@mui/icons-material/Payment'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import Place from '@mui/icons-material/Place'
import PriorityHigh from '@mui/icons-material/PriorityHigh'
import Phone from '@mui/icons-material/Phone'
import QrCode2 from '@mui/icons-material/QrCode2'
import Receipt from '@mui/icons-material/Receipt'
import Share from '@mui/icons-material/Share'
import ShoppingCart from '@mui/icons-material/ShoppingCart'
import Shortcut from "@mui/icons-material/Shortcut";
import Storefront from '@mui/icons-material/Storefront'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import WhatsApp from '@mui/icons-material/WhatsApp'

function Icon ({icon="donut-small", customStyles={}}){
    if (typeof icon !== 'string'){
        logError(`Icon: icon supplied is not a string ${icon}`)
        return null
    }
    icon = icon.toLowerCase()
    let SelectedIcon = DonutSmall
    switch (icon) {
        case 'account-circle' : SelectedIcon = AccountCircle; break;
        case 'add' : SelectedIcon = Add; break;
        case 'arrow-back' : SelectedIcon = ArrowBack; break;
        case 'arrow-drop-down' : SelectedIcon = ArrowDropDown; break;
        case 'arrow-drop-up' : SelectedIcon = ArrowDropUp; break;
        case 'close' : SelectedIcon = Close; break;
        case 'content-copy' : SelectedIcon = ContentCopy; break;
        case 'done' : SelectedIcon = Done; break;
        case 'download' : SelectedIcon = Download; break;
        case 'email' : SelectedIcon = Email; break;
        case 'home' : SelectedIcon = Home; break;
        case 'keyboard-arrow-down' : SelectedIcon = KeyboardArrowDown; break;
        case 'keyboard-arrow-left' : SelectedIcon = KeyboardArrowLeft; break;
        case 'keyboard-arrow-right' : SelectedIcon = KeyboardArrowRight; break;
        case 'keyboard-arrow-up' : SelectedIcon = KeyboardArrowUp; break;
        case 'local-mall' : SelectedIcon = LocalMall; break;
        case 'logout' : SelectedIcon = Logout; break;
        case 'monetization-on' : SelectedIcon = MonetizationOn; break;
        case 'more-vert' : SelectedIcon = MoreVert; break;
        case 'open-in-new' : SelectedIcon = OpenInNew; break;
        case 'payment' : SelectedIcon = Payment; break;
        case 'photo-camera' : SelectedIcon = PhotoCamera; break;
        case 'place' : SelectedIcon = Place; break;
        case 'priority-high' : SelectedIcon = PriorityHigh; break;
        case 'phone' : SelectedIcon = Phone; break;
        case 'qr-code-2' : SelectedIcon = QrCode2; break;
        case 'receipt' : SelectedIcon = Receipt; break;
        case 'share' : SelectedIcon = Share; break;
        case 'shopping-cart' : SelectedIcon = ShoppingCart; break;
        case 'shortcut' : SelectedIcon = Shortcut; break;
        case 'storefront' : SelectedIcon = Storefront; break;
        case 'visibility' : SelectedIcon = Visibility; break;
        case 'visibility-off' : SelectedIcon = VisibilityOff; break;
        case 'whatsapp' : SelectedIcon = WhatsApp; break;
        default: SelectedIcon = DonutSmall
    }
    return (
        <SelectedIcon style={customStyles} className={'icon'}/>
    )
}
export default Icon