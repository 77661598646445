import { useState, useEffect } from "react"
import FormField from "../../components/FormField"
import ProductSelectionListItem from "../../components/ProductSelectionListItem"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

/**
 * Allow the user to select from a list of their products
 * @param {Object} props
 * @param {Array} props.payeeProductsArray - Used to pass in the list of products. If not provided, will load products based on the payee id
 * @param {Object} props.selectedIdMap - used to indicate which products are selected
 * @param {string} props.payeeId - used to load products if payee products array is not provided
 * @param {function} props.filter - filters the payeeproducts, regardless of how they're loaded
 * @param {Object} props.payeeProducts - redux
 * @param {Object} props.actions
 * @returns 
 */
const PayeeProductSelectionList = ({
    payeeProductsArray, //used if you want to pass in the list of products to select from
    selectedIdMap={},
    filter=()=>true,
    payeeId="",
    buttonText="Save Selection",
    title="Select Products",
    payeeProducts,
    actions,
    onChange=()=>{},
    onSubmit=()=>{}
}) => {
    /**
     * TODO
     * listen intelligently to the payee's products by
     * 1. only loading props if the last loaded time was over 6 hrs ago
     * 2. listening to products created since
     * 3. listening to products edited
     * **/
    const [payeeProductsListener, setPayeeProductsListener] = useState(() => () => {})
    const listenToPayeeProducts = async () => {
        const newPayeeProductsListener = await actions.fetchSubscribeToPayeeProductsForPayee(payeeId)
        setPayeeProductsListener(() => newPayeeProductsListener)
    }
    useEffect(() => {
        if (!payeeProductsArray && payeeId) {
            listenToPayeeProducts()
        }
        return () => {
            if (typeof payeeProductsListener === "function") payeeProductsListener()
        }
    }, [payeeId])

    const [searchTerm, setSearchTerm] = useState("")
    const productsArray = payeeProductsArray ?
                            payeeProductsArray //use payee products array if provided 
                            :
                            //otherwise, use the products from redux
                            Object.values(payeeProducts.payeeProductsById)
                                .filter(p => p.payeeId === payeeId && !p.deleted)
    const trimmedSearchTerm = searchTerm.trim().toLowerCase()
    const filteredProductsArray = productsArray.filter(p => 
                                        (!trimmedSearchTerm || p.name.toLowerCase().includes(trimmedSearchTerm)) && //search if search term provided
                                        filter(p) //filter out based on function from parent
                                    )
    //select products in the list
    const [selectedProducts, setSelectedProducts] = useState(selectedIdMap)
    //select / deselect products
    const handleSelectProduct = (productId, selected) => {
        const selectionMap = {...selectedProducts}
        if (selected) selectionMap[productId] = true
        else delete selectionMap[productId]
        setSelectedProducts(selectionMap)
        onChange(selectionMap)
    }
    return (
        <div className="formContainer">
            <div className="formHeader">
                    <div className="h2 text-align-center">{title}</div>
                </div>
            <div className="formBody">
                <FormField
                    value={searchTerm} 
                    onChange={e => setSearchTerm(String(e.target.value))}
                    placeholder={`Search By Product Name`}
                    inputIconButton={"close"}
                    onClickInputIconButton={() => setSearchTerm("") }
                />
                <div className="overflow-y-auto max-height-80vh">
                    {
                        filteredProductsArray.map(p => {
                            const selected = Boolean(selectedProducts[p.id])
                            return (
                                <div key={p.id}>
                                    <ProductSelectionListItem
                                        product={p}
                                        selected={selected}
                                        onChange={() => handleSelectProduct(p.id, !selected)}
                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="formFooter">
                <button className="button secondary width-100pct" onClick={() => onSubmit(selectedProducts)}>{buttonText}</button>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    payeeProducts: state.payeeProducts,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PayeeProductSelectionList)