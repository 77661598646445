import React, { useState, useRef, useEffect } from "react";
import Icon from "../Icon"
const ImageWithPlaceholder = ({ src, alt, containerClassName, placeholderIcon="shopping-cart", lazyLoad=true }) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [loadedSrc, setLoadedSrc] = useState(lazyLoad ? null : src);
  const wrapperRef = useRef(null); 

  //check when the container enters the viewport and then set the image's src
  useEffect(() => {
      if (!lazyLoad || !wrapperRef.current) return;

      const observer = new IntersectionObserver(
          ([entry]) => {
              if (entry.isIntersecting) {
                  setLoadedSrc(src); // Start loading the actual image
                  observer.disconnect(); // Stop observing after loading
              }
          },
          { rootMargin: "100px" } // Load images slightly before they appear
      );

      observer.observe(wrapperRef.current);

      return () => observer.disconnect();
  }, [src, lazyLoad]);

  return (
    <div ref={wrapperRef} className={`position-relative width-100% height-100% ${containerClassName}`}>
        {
            showPlaceholder && 
            (
                <div
                    className={"position-absolute inset-0 display-flex align-items-center justify-content-center background-color-light-grey color-grey font-size-32px"}
                >
                    <Icon icon={placeholderIcon} />
                </div>
            )
        }
      <img
        src={loadedSrc}
        alt={alt}
        onLoad={() => setShowPlaceholder(false)}
        onError={() => setShowPlaceholder(true)}
       
        className={`transition-opacity transition-duration-500 ${showPlaceholder ? "opacity-0" : "opacity-100"}`}
        style={{ display: showPlaceholder ? "none" : "block" }}
      />
    </div>
  );
};

export default ImageWithPlaceholder;