import StatusSelect from "../../components/StatusSelect";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import {
    PAYEE_CATALOG_TYPE_DEFAULT,
} from "../../constants/payeeCatalog"

import {
    PAYEE_ORDER_STATUS_READY_FOR_PICKUP,
    PAYEE_ORDER_STATUS_COMPLETED,
    PAYEE_ORDER_STATUS_CANCELLED,
} from "../../constants/payeeOrder"
import { shortenReference } from "../../utils/interfaceUtils";

import { getPayeeOrderStatusOptions } from "../../utils/payeeOrderUtils";
const PayeeOrderStatusSelect = ({
    payeeOrder,
    currentStatus,
    catalogType=PAYEE_CATALOG_TYPE_DEFAULT,
    actions
}) => {
    const {customerName="",  reference="", id} = payeeOrder || {}
    const handleChange = async newStatus => {
        if(!window.confirm(`Change status to ${newStatus} from ${currentStatus} for ${customerName}'s order - ${shortenReference(reference)}?`)) return
        actions.toggleLoading(true)
        await actions.fetchUpdatePayeeOrder(
            id,
            {
                currentStatus: newStatus,
                statusHistory: {[newStatus]: Date.now()}
            }
        )
        actions.toggleLoading(false)
    }
    //set the current status options based on the catalogType and the current status
    const statusOptions = getPayeeOrderStatusOptions(catalogType, currentStatus, Boolean(payeeOrder.closedAt))
    //get the current style based on the current status
    const getStyle = status => status === PAYEE_ORDER_STATUS_COMPLETED ? {backgroundColor: "var(--success-green)"} : 
                               status === PAYEE_ORDER_STATUS_CANCELLED ? {backgroundColor: "var(--error-red)"} :
                               status === PAYEE_ORDER_STATUS_READY_FOR_PICKUP ? {backgroundColor: "var(--info-blue)", color: "white"} : {}
    return (
        <StatusSelect 
            currentStatus={currentStatus}
            statusOptions={statusOptions}
            onChange={handleChange}
            getStyle={getStyle}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(PayeeOrderStatusSelect)