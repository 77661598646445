import { useState, useEffect } from "react"
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal"
import ImageWithPlaceholder from "../../components/ImageWithPlaceholder"
import ProductVariantPicker from "../../components/ProductVariantPicker"
import { truncateStringWithEllipsis } from "../../utils/stringUtils"
import styles from "./ProductDetails.module.css"

/**
 * General purpose, visually appealing display of a payee product. Used in ProductSelectionListItem etc
 * @param {Object} props 
 * @param {Object} props.product
 * @param {string} defaultActiveVariantId controls the variant picker
 * @param {boolean} showDescription show / hide description
 * @param {boolean} showVariants show / hide variants picker
 * @param {boolean} wrapText allow wrapping of description and name or apply no wrap. Affects the display significantly 
 * @param {function} onChangeActiveVariantId 
 */
const ProductDetails = ({
    product={},
    defaultActiveVariantId="", 
    showDescription=true,
    showVariants=true,
    wrapText=true,
    onChangeActiveVariantId=()=>{}
}) => {
    const {
        id, name="", price=0, description="", 
        defaultVariantId="", variantsById={}, hasVariants=false,  
        payeeId=""
    } = product
    const [activeVariantId, setActiveVariantId] = useState(
        //if a default selected 
        defaultActiveVariantId && variantsById[defaultActiveVariantId] ? 
        defaultActiveVariantId
        :
        defaultVariantId
    )
    //if the default active variant changes, adjust it in state
    useEffect(() => {
        if (defaultActiveVariantId) setActiveVariantId(defaultActiveVariantId)
    }, [defaultActiveVariantId])
    const activeVariant = variantsById[activeVariantId]
    if (!activeVariant) return ""
    const activeImageUrl = activeVariant.images && activeVariant.images[0] && activeVariant.images[0].med ?
                            activeVariant.images[0].med
                            :
                            product.imageUrl
    return (
        <div className={`display-flex flex-direction-row align-items-center width-100pct ${wrapText ? "" : styles.nowrap}`}>
            <div className={`${styles.image}`}>
                <ImageWithPlaceholder src={activeImageUrl} containerClassName="productImageContainer"/>
            </div> 
            <div className={styles.textContainer}>
                <div className={styles.name}>{truncateStringWithEllipsis(name, 35)}</div>
                <div className="amount">EC$ <AmountSmallAfterDecimal amount={activeVariant.price}/></div>
                {
                    showDescription ?
                    <div className={styles.description}>{typeof description === "string" ? description.replaceAll("\\n", " "): ""}</div>
                    :
                    null
                }
                {
                    showVariants && hasVariants && Object.keys(variantsById).length > 1  ?
                    <div className={`margin-top-05em productVariantPickerContainer`}>
                            <ProductVariantPicker 
                                variantsById={variantsById} 
                                selectedVariantId={activeVariantId}
                                defaultImageUrl={activeImageUrl}
                                onChange={
                                    selectedVariantId => {
                                        setActiveVariantId(selectedVariantId)
                                        onChangeActiveVariantId(selectedVariantId)
                                    }
                                }
                                editable={true}
                            />
                    </div>
                    :
                    ""
                }
            </div>
        </div>
    )
}

export default ProductDetails