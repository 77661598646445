import React from "react"

import DataTable from "../../components/DataTable";
import DropdownMenu from "../../components/DropdownMenu";
import Icon from "../../components/Icon";

import {timestampToDateString} from "../../utils/datetimeUtils";
import {shortenReference} from "../../utils/interfaceUtils";
import { isMobile } from "../../utils/deviceUtils";
import {downloadExcel} from "../../utils/fileUtils"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const CustomerTable = ({
                          title = "Customers",
                          emptyText = "No Customers",
                          customersArray = [],  
                          actions
                      }) => {
    const exportCustomerReport = async () => {
        actions.toggleLoading(true)
        const reportData = customersArray.sort((cA, cB) => {
                                                const lastPaidAtA = cA.lastPaidAt ? cA.lastPaidAt : 0
                                                const lastPaidAtB = cB.lastPaidAt ? cB.lastPaidAt : 0;
                                                return lastPaidAtB - lastPaidAtA
                                            })
                                         .map(c => {
            const {name="", phoneNumber="", email="", lastPaidAt=0} = c
            return (
                {
                    name,
                    phone: phoneNumber,
                    email,
                    'Last Paid At': lastPaidAt? timestampToDateString(lastPaidAt, "yyyy-MM-dd HH:mm:ss") : ""
                }
            )
        })
        await downloadExcel(reportData, `Customer Report - Shopdm Pay - ${timestampToDateString(Date.now(), "EEE, dd MMMM yy 'at' t")}`)
        actions.toggleLoading(false)
    }

    const menuItems = [{
        label: `Export Customers`,
        onClick: exportCustomerReport
    }]
    return (
        <DataTable
            dataArray={customersArray}
            fields={[
                {
                    id: 'name',
                    header: "Name"
                },
                {
                    id: 'phoneNumber',
                    header: 'Phone',
                },
                {
                    id: 'email',
                    header: 'Email',
                },
                {
                    id: 'lastPaidAt',
                    header: 'Last Paid At',
                    transformFunction: lastPaidAt => lastPaidAt? timestampToDateString(lastPaidAt, "EEE, dd MMMM 'at' t") : ""
                },
                {
                    id: 'lastReference',
                    header: 'Last Reference',
                    transformFunction: lastReference => lastReference ? shortenReference(lastReference) : ""   
                },
                {
                    id: 'createdAt',
                    header: 'Created At',
                    transformFunction: createdAt => timestampToDateString(createdAt, "EEE, dd MMMM 'at' t")
                },
            ]}
            title={title}
            emptyText={emptyText}
            defaultSortBy="lastPaidAt"
            searchFields={["name", "lastReference", "phoneNumber", "email"]}
            maxRowsPerPage={isMobile() ? 4: 8}
            paginate={true}
            TopRight={
                () => <DropdownMenu 
                        menuItems={menuItems}
                        MenuIcon={
                            <div className={`iconContainer transparent`}>
                                <Icon icon="more-vert"/>
                            </div>
                        }
                    />
            }
        />
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(null, mapDispatchToProps)(CustomerTable)