import React, {useState} from "react"

import DataTable from "../../components/DataTable";
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal";
import DropdownMenu from "../../components/DropdownMenu";
import ResultModal from "../../components/ResultModal"

import PayeeOrderItemList from "../../containers/PayeeOrderItemList"
import Modal from "../../components/Modal";
import Icon from "../../components/Icon";
import PayeeOrderStatusSelect from "../../containers/PayeeOrderStatusSelect";

import {shortenReference} from "../../utils/interfaceUtils";
import {copyToClipboard} from "../../utils/interactionUtils";
import {timestampToDateString} from "../../utils/datetimeUtils";
import {countCartItems} from "../../utils/cartUtils"
import {downloadExcel} from "../../utils/fileUtils"

import {MESSAGE_TYPE_EMAIL, TEMPLATE_TYPE_PAYDM_SENDER_PAYEE_ORDER_CONFIRMATION_EMAIL} from "../../constants/messages"
import {RESULT_SUCCESS, RESULT_WARNING} from "../../constants/results"
import { PAYEE_ORDER_STATUS_CANCELLED, PAYEE_ORDER_STATUS_COMPLETED, PAYEE_ORDER_STATUS_READY_FOR_PICKUP } from "../../constants/payeeOrder";
import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {v4 as uuid4} from 'uuid'
import currency from "currency.js"
import { alphabeticalSort } from "../../utils/stringUtils";
const PayeeOrderTable = ({
                          title = "Orders",
                          payeeName = "",
                          emptyText = "No Orders",
                          payeeOrdersArray = [],
                          actions
                      }) => {
    payeeOrdersArray = payeeOrdersArray.map(p => ({
        ...p,
        customerName: p.payerData ? p.payerData.name : "",
        phoneNumber: p.payerData ? p.payerData.phoneNumber : "",
        email: p.payerData ? p.payerData.email : "",
    }))
    //display the order items list
    const [itemListPayeeOrderId, setItemListPayeeOrderId] = useState("")
    //resend confirmation email to customers
    const [confirmationEmailResult, setConfirmationEmailResult] = useState(false)
    const [confirmationEmailResultMessage, setConfirmationEmailResultMessage] = useState("")
    const resendConfirmationEmail = async payeeOrder => {
        const {paidByPayerName="", reference="", payeeId="", payerData={}, amountPaidXcd=0} = payeeOrder
        if (!window.confirm(`Are you sure you want to resend the order confirmation email to ${paidByPayerName} for Order #${shortenReference(reference)}?`)) return
        actions.toggleLoading(true)
        await actions.fetchCreateMessage(
            uuid4(),
            MESSAGE_TYPE_EMAIL,
            TEMPLATE_TYPE_PAYDM_SENDER_PAYEE_ORDER_CONFIRMATION_EMAIL,
            {email: payerData.email},
            {payeeOrderId: payeeOrder.id},
            `RESENT Payee order confirmed: For EC$${amountPaidXcd} to ${payeeName} by ${paidByPayerName} - ${reference}`,
            payeeId,
            () => {
                setConfirmationEmailResult(RESULT_SUCCESS)
                setConfirmationEmailResultMessage(`You've resent the confirmation email to ${paidByPayerName}`)
            },
            () => {
                setConfirmationEmailResult(RESULT_WARNING)
                setConfirmationEmailResultMessage(`Failed to send email. Check your internet connection and retry`)
            }
        )
        actions.toggleLoading(false)
    }

    const handleCancelOrder = async payeeOrder => {
        const {reference="", closedAt=0} = payeeOrder
        if (closedAt) return
        const shortenedReference = shortenReference(reference)
        const text = window.prompt(`Cancel order ${shortenedReference}? This action cannot be undone.\n\n Type reference ${shortenedReference} to cancel`)
        if(String(text).toLowerCase() !== String(shortenedReference).toLowerCase()) {
            //if something incorrect was provided, let the user know
            if (text) alert(`${text} did not match ${shortenedReference}`)
            return 
        }
        actions.toggleLoading(true)
        await actions.fetchUpdatePayeeOrder(
            payeeOrder.id,
            {
                currentStatus: PAYEE_ORDER_STATUS_CANCELLED,
                statusHistory: {[PAYEE_ORDER_STATUS_CANCELLED]: Date.now()}
            }
        )
        actions.toggleLoading(false)
    }
    const exportOrderReport = async () => {
        actions.toggleLoading(true)
        const formatLineItemsForExcel = (lineItemsById) => {
            return Object.values(lineItemsById)
                .map(item => {
                    const {quantity=0, price=0, name="", variantDetails=""} = item
                    const totalPrice = currency(price).multiply(quantity);
                    const variantString = !variantDetails || variantDetails === "Regular"? "" : `(${variantDetails}) ` 
                    return quantity > 1
                        ? `${quantity} x ${name} ${variantString}EC$${totalPrice.toString()} / ${currency(price).toString()} each`
                        : `${quantity} x ${name} ${variantString}EC$${totalPrice.toString()}`;
                })
                .join(",\n");
        }
        const reportData = payeeOrdersArray.sort((oA, oB) => {
                                                const paidAtA = oA.paidAt ? oA.paidAt : 0
                                                const paidAtB = oB.paidAt ? oB.paidAt : 0;
                                                return paidAtB - paidAtA
                                            })
                                         .map(o => {
                                                const {reference="", customerName="", lineItemsById={}, totalXcd=0, currentStatus="", phoneNumber="", email="", paidAt=0} = o
                                                return (
                                                    {
                                                        reference: shortenReference(reference),
                                                        'customer name': customerName,
                                                        'items': formatLineItemsForExcel(lineItemsById),
                                                        'total (EC$)': Number(totalXcd).toFixed(2),
                                                        'status': currentStatus,
                                                        'phone': phoneNumber,
                                                        email,
                                                        'Paid At': paidAt? timestampToDateString(paidAt, "yyyy-MM-dd HH:mm:ss") : ""
                                                    }
                                                )
                                            })
        await downloadExcel(reportData, `Order Report - Shopdm Pay - ${timestampToDateString(Date.now(), "EEE, dd MMMM yy 'at' t")}`)
        actions.toggleLoading(false)
    }

    const exportSalesReport = async () => {
        actions.toggleLoading(true)
        const productSalesByVariantIdMap = {}
        payeeOrdersArray.map(o => {
            const {reference="", customerName="", lineItemsById={}} = o
            Object.values(lineItemsById)
                    .forEach(item => {
                        const {quantity=0, price=0, name="", variantDetails=""} = item
                        if (!productSalesByVariantIdMap[item.id]) {
                            const variantString = !variantDetails || variantDetails === "Regular"? "" : ` (${variantDetails})` 
                            productSalesByVariantIdMap[item.id] = {
                                id: item.id,
                                name: `${name}${variantString}`,
                                price,
                                totalSold: 0,
                                totalEarned: 0,
                                orders: [],
                                customerNames:{}
                            }
                        }
                        const sales = productSalesByVariantIdMap[item.id]
                        sales.totalSold = currency(sales.totalSold).add(quantity).value;
                        sales.totalEarned = currency(sales.totalEarned).add(
                                                    currency(quantity).multiply(price) 
                                          ).value;
                        sales.orders.push(shortenReference(reference))
                        sales.customerNames[customerName] = true
                    })
            })
            const reportData = Object.values(productSalesByVariantIdMap)
                                        .sort((sA, sB) =>  alphabeticalSort(sA.name, sB.name))
                                        .map(sales => {
                                            const {name, price, totalSold, totalEarned, orders=[], customerNames=[]} = sales 
                                return (
                                    {
                                        'product name': name,
                                        price,
                                        'amount sold': totalSold,
                                        'total earned (EC$)': Number(totalEarned).toFixed(2),
                                        'orders': orders.join(",\n"),
                                        'customers': Object.keys(customerNames).join(",\n"),
                                    }
                                )
                            })
        await downloadExcel(reportData, `Product Sales Report - Shopdm Pay - ${timestampToDateString(Date.now(), "EEE, dd MMMM yy 'at' t")}`)
        actions.toggleLoading(false)
    }

    const menuItems = [
        {
            label: `Export Product Sales Report`,
            onClick: exportSalesReport
        },
        {
            label: `Export Orders Report`,
            onClick: exportOrderReport
        },
    ]
    //highlight rows as green if the order is completed 
    const rowHighlightFunction = ({currentStatus=""}) => currentStatus === PAYEE_ORDER_STATUS_COMPLETED ? "var(--light-success-green)" : 
                                                         currentStatus === PAYEE_ORDER_STATUS_CANCELLED ? "var(--light-error-red)" : 
                                                         currentStatus === PAYEE_ORDER_STATUS_READY_FOR_PICKUP ? "var(--light-info-blue)" : 
                                                         ""
    return (
        <>
            <DataTable
                dataArray={payeeOrdersArray}
                fields={[
                    {
                        id: 'reference',
                        header: 'Reference',
                        transformFunction: reference => <a className="actionText"
                                                        onClick={() => copyToClipboard(shortenReference(reference))}>{shortenReference(reference)}</a>
                    },
                    {
                        id: 'customerName',
                        header: "Name"
                    },
                    {
                        id: 'lineItemsById',
                        header: 'Items',
                        hideSort: true,                                                                 
                        transformFunction: (lineItemsById, id, i, o) => <button className="button small secondary" onClick={() => setItemListPayeeOrderId(id)}>View {countCartItems(o)} items</button>
                    },
                    {
                        id: 'totalXcd',
                        header: 'Total',
                        transformFunction: totalXcd => <div className="font-weight-600">EC$<AmountSmallAfterDecimal
                            amount={totalXcd}/></div>
                    },
                    {
                        id: 'currentStatus',
                        header: 'Status',
                        transformFunction: (currentStatus, id, key, o) => <PayeeOrderStatusSelect 
                                                                                payeeOrder={o}
                                                                                currentStatus={currentStatus}
                                                                                catalogType={o.payeeCatalogType}
                                                                            />
                    },
                    {
                        id: 'phoneNumber',
                        header: "Phone"
                    },
                    {
                        id: 'email',
                        header: "Email"
                    },
                    {
                        id: 'paidAt',
                        header: 'Paid At',
                        transformFunction: paidAt => timestampToDateString(paidAt, "EEE, dd MMMM 'at' t")
                    },
                    // {
                    //     id: 'id', //quickAction
                    //     header: "",
                    //     hideSort: true,
                    //     transformFunction: (id, key, i, invoice) => <InvoiceQuickActionButton invoice={invoice}
                    //                                                                           payeeHandleId={payeeHandleId}
                    //                                                                           payeeName={payeeName}
                    //                                                                           />
                    // },
                    {
                        id: 'id', //actions
                        header: "",
                        hideSort: true,
                        transformFunction: (id, key, i, payeeOrder) => <DropdownMenu
                                                                        menuItems={[
                                                                            {
                                                                                label: "Resend Confirmation Email",
                                                                                onClick: () => resendConfirmationEmail(payeeOrder),
                                                                            },
                                                                            {
                                                                                label: "Cancel Order",
                                                                                onClick: () => handleCancelOrder(payeeOrder),
                                                                            }
                                                                        ]}
                                                                        MenuIcon={
                                                                            <div className={`iconContainer transparent`}>
                                                                                <Icon icon="more-vert"/>
                                                                            </div>
                                                                        }
                                                                    />
                    }
                ]}
                title={title}
                emptyText={emptyText}
                defaultSortBy="paidAt"
                searchFields={["reference", "customerName", "phoneNumber", "email", "currentStatus",]}
                TopRight={
                    () => <DropdownMenu 
                            menuItems={menuItems}
                            MenuIcon={
                                <div className={`iconContainer transparent`}>
                                    <Icon icon="more-vert"/>
                                </div>
                            }
                        />
                }
                rowHighlightFunction={rowHighlightFunction}
            />
            {
                itemListPayeeOrderId ?
                <Modal
                        isOpen={Boolean(itemListPayeeOrderId)}
                        closeModal={() => setItemListPayeeOrderId("")}
                        className="height-fit-content max-height-100pct"
                        overlayClassName="flex-center"
                        closeOnOverlayClick={true}
                        showCloseButton={true}
                    >
                        <PayeeOrderItemList payeeOrderId={itemListPayeeOrderId} payeeName={payeeName}/>
                    </Modal>
                :
                null
            }
            {
                confirmationEmailResult ? 
                <ResultModal 
                    result={confirmationEmailResult}
                    autoClose={confirmationEmailResult === RESULT_SUCCESS}
                    message={confirmationEmailResultMessage}
                    onClose={() => {
                        setConfirmationEmailResult(false)
                        setConfirmationEmailResultMessage("")
                    }}
                    autoCloseTimeout={2000}
                />
                    : 
                null
            }
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(PayeeOrderTable)