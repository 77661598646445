import {useState, useRef} from "react"

const EditableLabel = ({label="", onChange=()=>{}, validate=()=>true}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(label);
    const lastValue = useRef(label)
    const handleBlur = () => {
      setIsEditing(false);
      if (!validate(value)) setValue(lastValue.current)  
      else {
        lastValue.current = value
        onChange(value);
      }
    };
    return (
        <>
        {isEditing ? (
          <input
            className={"material-input"}
            value={value}
            autoFocus
            onChange={(e) => setValue(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={(e) => e.key === "Enter" && handleBlur()}
          />
        ) : (
          <span className="cursor-pointer hover-background-primary" onClick={() => setIsEditing(true)}>
            {label}
          </span>
        )}
        </>
    )
}

export default EditableLabel