import React, {useState, useEffect, useRef} from "react"
import FormField from "../../components/FormField"
import { DEFAULT_PAYER_VALUES_US } from "../../constants/payers"
import { COUNTRY_CODES } from "../../constants/country"

const PayerForm = ({
    payerData=DEFAULT_PAYER_VALUES_US,
    handleChange=() => {},
    handleKeyDown=()=>{}, 
    errors={}, 
    defaultShowAllPayerFields=false,
    billingAddressLabel="Billing Address"
}) => {
    const [showAllPayerFields, setShowAllPayerFields] = useState(defaultShowAllPayerFields)
    const [phoneInfo, setPhoneInfo] = useState({})
    const {
        phoneNumber="", name="", email="", 
        address={
            city: "",
            line1: "",
            zipCode: "",
            countryName: "",
            countryCode: ""
        }
    } = payerData

    const prevPhoneNumber = useRef();
    //Compare previous and current phone number
    //whenever the phone number changes, display the other fields
    useEffect(() => {
        if (prevPhoneNumber.current !== undefined) {
            if (prevPhoneNumber.current !== phoneNumber) {
                setShowAllPayerFields(true)
            }
        }
        // Update the ref to the current value
        prevPhoneNumber.current = phoneNumber;
    }, [phoneNumber]); 
    //whenever there are errors, display all fields 
    useEffect(() => {
        if (Object.keys(errors).length > 0 && !showAllPayerFields){
            setShowAllPayerFields(true)
        }
    }, [errors])

    /**
     * On phones it is tedious to scroll through all of the countries in the world,
     * so we help the user by auto-selecting the country when they change phone number
     */
    const handleChangePhoneNumber = (value, phoneInfo) => {
        //1. update phone number
        handleChange(value, "phoneNumber")
        setPhoneInfo(phoneInfo)
    }

    useEffect(() => {
        //2 (a) get the country code as indicated by the phone's flag input
        const {countryCode=""} = phoneInfo || {}
        const {name:countryName=""} = COUNTRY_CODES[countryCode] || {}
        const address = {...payerData.address}
        //if the country of the phone's flag input does not match
        //the currently selected country in the address, then update it
        if (
            countryCode &&
            countryName &&
            address.countryCode !== countryCode
        ){
            //if the address was dominica and the default city of Roseau was provided,
            //then clear it
            if (address.countryCode === "DM"){
                if (address.city.toLowerCase() === "roseau") address.city = ""
            } 
            //if instead the country is being set to Dominica, then set Roseau as default
            else if (countryCode === "DM") address.city = "Roseau"
            address.countryCode = countryCode
            address.countryName = countryName
            handleChange(address, "address")
        }
    }, [phoneInfo])
    return (
    <>
        <FormField
            type="tel"
            label="Phone Number"
            value={phoneNumber}
            onKeyDown={handleKeyDown}
            errorText={errors.phoneNumber}
            onChange={handleChangePhoneNumber}
            note={
                <div className="a label margin-top-05em">
                    <a onClick={() => setShowAllPayerFields(!showAllPayerFields)}>
                        {showAllPayerFields ? 'Hide Details' : 'Show Details'}
                    </a>
                </div>
            }
        />

        {
            showAllPayerFields && 
            (
                <>
                    <div className="h4 text-align-center margin-top-05em">{billingAddressLabel}</div>
                    <FormField
                        value={name}
                        label={"Full Name"}
                        errorText={errors.name}
                        onKeyDown={handleKeyDown}
                        onChange={e => handleChange(e.target.value, "name")}
                    />
                    <FormField
                        label={<div>Address</div>}
                        type="address"
                        value={address}
                        errorText={errors.address}
                        onKeyDown={handleKeyDown}
                        errorObject={errors.addressErrorObject}
                        onChange={value => handleChange(value, 'address')}
                    />
                    <FormField
                        type={"email"}
                        label="Email"
                        value={email}
                        errorText={errors.email}
                        onKeyDown={handleKeyDown}
                        onChange={e => handleChange(e.target.value, "email")}
                    />
                </>
            )
        }
    </>
    )
}

export default PayerForm