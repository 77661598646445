import React, {useState, useEffect} from "react"

import DataTable from "../../components/DataTable";
import RadioButton from "../../components/RadioButton";
import Icon from "../../components/Icon";
import Modal from "../../components/Modal";
import DropdownMenu from "../../components/DropdownMenu";

import CreatePayeeCatalogForm from "../../forms/CreatePayeeCatalogForm";
import UpdatePayeeCatalogForm from "../../forms/UpdatePayeeCatalogForm";

import {timestampToDateString} from "../../utils/datetimeUtils";
import {getPayeeCatalogLink} from "../../utils/payeeCatalogUtils"

import withQRScanModal from "../../hocs/withQRScanModal"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const PayeeCatalogTable = ({
                          title = "Catalogs",
                          payeeHandleId = "",
                          payeeId={},
                          payeeName = "",
                          transactionLimitXcd = 0,
                          emptyText = "No Catalogs",
                          payeeCatalogsArray = [],
                          defaultSelectedId="",
                          onChangeSelectedId=()=>{},
                          setQRScanLink = () => {},
                          setPaymentRequestMessage = () => {},
                          actions
                      }) => {
    const [selectedCatalogId, setSelectedCatalogId] = useState(defaultSelectedId)
    useEffect(() => {setSelectedCatalogId(defaultSelectedId)}, [defaultSelectedId])
    const handleChangeSelectedCatalogId = selectedCatalogId => {
        setSelectedCatalogId(selectedCatalogId)
        onChangeSelectedId(selectedCatalogId)
    }
    //open / close create catalog form
    const [createPayeeCatalogModalOpen, setCreatePayeeCatalogModalOpen] = useState(false)
    //open the update modal to update existing catalogs
    const [updatePayeeCatalogModalId, setUpdatePayeeCatalogModalId] = useState("")
    //toggle the catalog's active/inactive state
    const togglePayeeCatalogIsActive = async (id, isActive) => {
        actions.toggleLoading(true)
        await actions.fetchUpdatePayeeCatalog(id, {isActive})
        actions.toggleLoading(false)
    }
    //delete the catalog
    const deletePayeeCatalog = async (payeeCatalog) => {
        if (payeeCatalog.orderCount > 0) {
            alert(`You cannot delete catalog that have orders. Deactivate the catalog instead`)
            return
        }
        const deletedCatalogName = prompt(`Type "${payeeCatalog.name}" to confirm you want to delete this catalog`)
        if (!deletedCatalogName) return
        if (
            String(deletedCatalogName).trim().toLowerCase() === String(payeeCatalog.name).trim().toLowerCase()
        ) {
            actions.toggleLoading(true)
            await actions.fetchDeletePayeeCatalog(payeeCatalog.id)
            actions.toggleLoading(false)
        } else alert(`Catalog name is incorrect`)
    }
    //display QR scan modal
    const handleOpenLinkModal = catalog => {
        const {name} = catalog
        setPaymentRequestMessage(`Order from ${name} by ${payeeName} on Shopdm Pay`)
        setQRScanLink(getPayeeCatalogLink(catalog.handleId, payeeHandleId))
    }
    //highlight rows as grey if the catalog is deactivated 
    const rowHighlightFunction = payeeCatalog => !payeeCatalog.isActive ? "var(--grey)" : ""
    return (
        <>
            <DataTable
                dataArray={payeeCatalogsArray}
                fields={[
                    {
                        id: 'id',
                        header: "",
                        hideSort: true,
                        transformFunction: id => <RadioButton 
                                                    checked={id === selectedCatalogId}
                                                    onChange={() => handleChangeSelectedCatalogId(id)}
                                                />
                    },
                    {
                        id: 'headerImage',
                        header: 'Header',
                        hideSort: true,
                        transformFunction: headerImage => headerImage && headerImage.small ? <img src={headerImage.small} width={80} maxheight={80} /> : null
                    },
                    {
                        id: 'name',
                        header: 'Name',
                        transformFunction: (name, id, key, catalog) =>  <div className="display-flex">
                                                        <div>
                                                            {name}
                                                        </div>
                                                        <div className={`iconContainer transparent`} onClick={() => handleOpenLinkModal(catalog)}>
                                                            <Icon icon="open-in-new"/>
                                                        </div> 
                                                    </div>
                    },
                    {
                        id: 'catalogType',
                        header: "Type"
                    },
                    // {
                    //     id: 'payeeProductIds',
                    //     header: 'Products',
                    //     transformFunction: (payeeProductIds, id, key, payeeProduct) => <div>{payeeProductIds ? `${payeeProductIds.length} products` : ""}</div>
                    // },
                    {
                        id: 'views',
                        header: 'Views',
                        transformFunction: views => <div>{views? views : 0}</div>
                    },
                    // {
                    //     id: 'createdAt',
                    //     header: 'Created At',
                    //     transformFunction: createdAt => timestampToDateString(createdAt, "EEE, dd MMMM 'at' t")
                    // },
                    {
                        id: 'id',
                        header: " ",
                        hideSort: true,
                        transformFunction: (id, key, i, catalog) => {
                                                                const menuActions = [
                                                                    {
                                                                        name: "Send Catalog Link",
                                                                        label: "Send Catalog Link",
                                                                        onClick: () =>  handleOpenLinkModal(catalog)
                                                                    },
                                                                    {
                                                                        name: "Update Catalog",
                                                                        label: "Update Catalog",
                                                                        onClick: () => setUpdatePayeeCatalogModalId(id)
                                                                    },
                                                                ]
                                                                const {isActive} = catalog
                                                                if (!isActive) {
                                                                    menuActions.push({
                                                                        name: "Activate Catalog",
                                                                        label: "Activate Catalog",
                                                                        onClick: () => togglePayeeCatalogIsActive(id, !isActive)
                                                                    })
                                                                } else {
                                                                    menuActions.push({
                                                                        name: "Deactivate Catalog",
                                                                        label: "Deactivate Catalog",
                                                                        onClick: () => togglePayeeCatalogIsActive(id, !isActive)
                                                                    })
                                                                }
                                                                menuActions.push({
                                                                    name: "Delete Catalog",
                                                                    label: "Delete Catalog",
                                                                    onClick: () => deletePayeeCatalog(catalog)
                                                                })
                                                                return (
                                                                    <DropdownMenu
                                                                        menuItems={menuActions}
                                                                        MenuIcon={
                                                                            <div className={`iconContainer transparent`}>
                                                                                <Icon icon="more-vert"/>
                                                                            </div>
                                                                        }
                                                                    />
                                                                )
                                                            }
                    }
                    
                ]}
                title={title}
                emptyText={emptyText}
                TopRight={() => <button className="button gradient icon" onClick={() => setCreatePayeeCatalogModalOpen(true)}><Icon icon="add"/>New Catalog</button>}
                defaultSortBy="createdAt"
                searchFields={["name"]}
                rowHighlightFunction={rowHighlightFunction}
            />
            <Modal
                isOpen={createPayeeCatalogModalOpen}
                closeModal={() => setCreatePayeeCatalogModalOpen(false)}
                showCloseButton={false}
                className="height-fit-content max-height-100pct display-width-400px"
                overlayClassName="flex-center padding-1pct"
                innerClassName="max-height-80vh overflow-y-auto"
                closeOnOverlayClick={true}
            > 
                <CreatePayeeCatalogForm 
                    payeeId={payeeId}
                    transactionLimitXcd={transactionLimitXcd}
                    onSuccess={() => setCreatePayeeCatalogModalOpen(false)}
                />
            </Modal>
            <Modal
                isOpen={Boolean(updatePayeeCatalogModalId)}
                closeModal={() => setUpdatePayeeCatalogModalId("")}
                showCloseButton={false}
                className="height-fit-content max-height-100pct display-width-400px"
                overlayClassName="flex-center padding-1pct"
                innerClassName="max-height-80vh overflow-y-auto"
                closeOnOverlayClick={true}
            > 
                <UpdatePayeeCatalogForm
                    payeeCatalogId={updatePayeeCatalogModalId}
                    payeeId={payeeId}
                    onSuccess={() => setUpdatePayeeCatalogModalId("")}
                    transactionLimitXcd={transactionLimitXcd}
                />
            </Modal>
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(withQRScanModal(PayeeCatalogTable))