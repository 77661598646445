import {useState} from "react";

import FormField from "../FormField";
import IconButton from "../IconButton"

const AddListItemInput = ({
    label="",
    placeholder="",
    buttonLabel="Add",
    buttonBackgroundColor="var(--secondary)",
    buttonColor="white",
    buttonIcon="add", 
    onChange=()=>{},
    validate=()=>({success: true})
}) => {
    const [inputValue, setInputValue] = useState("");
    const [error, setError] = useState(null);
  
    const addListItem = () => {
        const value = inputValue.trim()
      if (!value) return;
      const {success, error} = validate(value)
      if (!success) {
          setError(error)
          return
      }
      onChange(value);
      setInputValue("");
    };
  
    return (
            <div>
                {label? <div className="label">{label}</div> : ""}
                <div className="display-flex gap-2 align-items-center">  
                    <FormField
                        value={inputValue}
                        onChange={(e) => {
                                setInputValue(e.target.value)
                                setError(null)
                            }
                        }
                        onKeyDown={(e) => e.key === "Enter" && addListItem()}
                        placeholder={placeholder}
                        errorText={error ? error : ""}
                    />
                    <div>
                        <IconButton 
                            onClick={addListItem} 
                            icon={buttonIcon}
                            label={buttonLabel}
                            size="small"
                            buttonStyle={{
                                backgroundColor: buttonBackgroundColor,
                                color: buttonColor
                            }}
                        />
                    </div>
                </div>
            </div>
    );
  };
  
  export default AddListItemInput;
  