/**
 *  Purpose: the modifications that are carried on the Payee Order objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 // STATE
 // payeeOrders:{
 //  payeeOrdersById:{},
 //  lastLoadedByPayeeId: {},
 //  lastUpdatedAt: 0
 // }
 
const payeeOrdersReducer = (state = initialState.payeeOrders, action) => {
    const {type, payload} = action;
    let payeeOrdersById = {...state.payeeOrdersById}
    let lastLoadedByPayeeId = {...state.lastLoadedByPayeeId}
    switch (type){
        case types.SAVE_PAYEE_ORDERS: {
           if(typeof payload.payeeOrders !== "object") {
               logError(`payeeOrdersReducer > SAVE_PAYEE_ORDERS: payeeOrders payload is not an object ${JSON.stringify(payload)}`)
               return state
           }
           if(typeof payload.payeeId !== "string") {
               logError(`payeeOrdersReducer > SAVE_PAYEE_ORDERS: payeeId in payload is not a string ${JSON.stringify(payload)}`)
               return state
           }
           payload.payeeOrders.forEach(payeeOrder => {
               payeeOrdersById[payeeOrder.id] = payeeOrder                                 
           })
           //track the last time payee orders were loaded for this payee
           if (payload.lastLoadedAt){
               lastLoadedByPayeeId[payload.payeeId] = payload.lastLoadedAt
           }
           return {
               ...state,
               payeeOrdersById,
               lastLoadedByPayeeId,
               lastUpdatedAt: Date.now()
           }
       }

       case types.CREATE_PAYEE_ORDER: {
           if(typeof payload.payeeOrder !== "object") {
               logError(`payeeOrdersReducer > CREATE_PAYEE_ORDER: payeeOrder payload is not an object ${JSON.stringify(payload)}`)
               return state
           }
           payeeOrdersById[payload.payeeOrder.id] = payload.payeeOrder
           return {
               ...state,
               payeeOrdersById,
               lastUpdatedAt: Date.now()
           }
       }

       case types.UPDATE_PAYEE_ORDER: {
        if(typeof payload.payeeOrderId !== "string") {
            logError(`payeeOrdersReducer > UPDATE_PAYEE_ORDER: payeeOrderId in payload is not a string ${JSON.stringify(payload)}`)
            return state
        }
        if(typeof payload.update !== "object") {
            logError(`payeeOrdersReducer > UPDATE_PAYEE_ORDER: update in payload is not an object ${JSON.stringify(payload)}`)
            return state
        }
        const {payeeOrderId, update} = payload
        
        return {
            ...state,
            payeeOrdersById: {
                ...payeeOrdersById,
                [payeeOrderId]: {
                    ...payeeOrdersById[payeeOrderId],
                    ...update
                }
            },
        }
    }

       case types.LOGOUT: {
           return initialState.payeeOrders
       }

        default: return state
    }
}

export default payeeOrdersReducer