import {
    PAYEE_CATALOG_TYPE_DEFAULT,
    PAYEE_CATALOG_TYPE_EVENT,
    PAYEE_CATALOG_TYPE_ORDERING,
    PAYEE_CATALOG_TYPE_BOOKING,
    PAYEE_CATALOG_TYPE_SCHEDULING,
    PAYEE_CATALOG_TYPE_DELIVERY,
} from "../constants/payeeCatalog"


import {
    PAYEE_ORDER_STATUS_CREATED,
    PAYEE_ORDER_STATUS_PAID,
    PAYEE_ORDER_STATUS_READY_FOR_PICKUP,
    PAYEE_ORDER_STATUS_COMPLETED,
    PAYEE_ORDER_STATUS_CANCELLED
} from "../constants/payeeOrder"

/**
 * Get the list of available statuses for the order, based on both the statuses for that catalog type and the current status
 * 
 * @param {string} catalogType The type of catalog that the order was made from
 * @param {string} currentStatus The current status of the order
 */
export const getPayeeOrderStatusOptions = (catalogType, currentStatus) => {
    //if the order is cancelled, the status cannot be changed again
    if (currentStatus === PAYEE_ORDER_STATUS_CANCELLED) return [PAYEE_ORDER_STATUS_CANCELLED]
    //choose the possible order statuses based on the catalog type
    const defaultOrderStatuses = [PAYEE_ORDER_STATUS_CREATED, PAYEE_ORDER_STATUS_PAID, PAYEE_ORDER_STATUS_COMPLETED]
    const orderStatusesByCatalogType = {
        [PAYEE_CATALOG_TYPE_DEFAULT]: defaultOrderStatuses,
        [PAYEE_CATALOG_TYPE_ORDERING]: [PAYEE_ORDER_STATUS_CREATED, PAYEE_ORDER_STATUS_PAID, PAYEE_ORDER_STATUS_READY_FOR_PICKUP, PAYEE_ORDER_STATUS_COMPLETED],
        [PAYEE_CATALOG_TYPE_DELIVERY]: [PAYEE_ORDER_STATUS_CREATED, PAYEE_ORDER_STATUS_PAID, PAYEE_ORDER_STATUS_COMPLETED],
        [PAYEE_CATALOG_TYPE_EVENT]: [PAYEE_ORDER_STATUS_CREATED, PAYEE_ORDER_STATUS_PAID, PAYEE_ORDER_STATUS_COMPLETED],
        [PAYEE_CATALOG_TYPE_SCHEDULING]: [PAYEE_ORDER_STATUS_CREATED, PAYEE_ORDER_STATUS_PAID, PAYEE_ORDER_STATUS_COMPLETED],
        [PAYEE_CATALOG_TYPE_BOOKING]: [PAYEE_ORDER_STATUS_CREATED, PAYEE_ORDER_STATUS_PAID, PAYEE_ORDER_STATUS_COMPLETED],
    }
    let statuses = orderStatusesByCatalogType[catalogType] ? 
                     orderStatusesByCatalogType[catalogType]
                     :
                     defaultOrderStatuses
    //filter the possible statuses by the current status, to get the currently available statuses 
    const currentStatusIndex = statuses.findIndex(s => s === currentStatus)
    //if the current status is not provided or found, return the full list of possible statuses
    if (!currentStatus || typeof currentStatus !== "string" || currentStatusIndex === -1) return statuses
    else {
        statuses = statuses.slice(
            currentStatusIndex, 
            currentStatusIndex + 2 //show the next available status, but not the one after that
        )
    }
    return statuses
}