import { useState, useEffect } from "react";
import {
    getWeekday,
    parseTime,
    addDays,
    subtractDuration,
    isBefore,
    isAfter
} from "../utils/datetimeUtils";
import { DOMINICA_TIMEZONE } from "../constants/datetime";

/**
 * Purpose: check if a payee is open and determine the next opening and closing times.
 *          consider the current time, their opening hours and an optional cutoff for orders
 * 
 * @param {object} openingHours 
 * @param {string} timezone 
 * @param {string} orderCutoff 
 * @returns 
 */
 const useIsOpen = (openingHours = {}, timezone = DOMINICA_TIMEZONE, orderCutoff = "00:00") => {
    const [isOpen, setIsOpen] = useState(false);
    const [nextOpeningTime, setNextOpeningTime] = useState(0);
    const [nextClosingTime, setNextClosingTime] = useState(0);

    useEffect(() => {
        const updateStatus = () => {
            if (document.visibilityState !== "visible") return; // Skip if the page is not visible
            const now = Date.now();
            const today = getWeekday(now, false, timezone);
            const currentDaySchedule = openingHours[today];
            let openingTime = 0 //the time it opened today, if it opened today
            if (currentDaySchedule && currentDaySchedule.openingTime && currentDaySchedule.closingTime) {
                openingTime = parseTime(currentDaySchedule.openingTime, now, timezone);
                let closingTime = parseTime(currentDaySchedule.closingTime, now, timezone);

                // Check if closing time is before opening time (meaning it extends past midnight)
                if (isBefore(closingTime, openingTime)) {
                    closingTime = addDays(closingTime, 1, timezone); // Move closing time to next day
                }

                // Apply the order cutoff time
                closingTime = subtractDuration(closingTime, orderCutoff, timezone);
                //The business is open if the current time is between the opening and closing times
                if (isAfter(now, openingTime) && isBefore(now, closingTime)) {
                    setIsOpen(true);
                    setNextClosingTime(closingTime);
                    return;
                }
            }
            //otherwise, the business is closed
            setIsOpen(false);
            //find the next opening time 

            //check whether it is opening later today
            if (now < openingTime) {
                setNextOpeningTime(openingTime)
                return;
            }
            else {
                let daysChecked = 0;
                let nextDayTimestamp = addDays(now, 1, timezone); //go to tomorrow
                let nextDay = getWeekday(nextDayTimestamp, false, timezone);
                //loop through the next week to find the next day / time the store will be open
                while (daysChecked < 7) {
                    const nextSchedule = openingHours[nextDay];
                    //if it opens on this day
                    if (nextSchedule && nextSchedule.openingTime) {
                        setNextOpeningTime(parseTime(nextSchedule.openingTime, nextDayTimestamp, timezone));
                        return;
                    }
                    //go to the next day and try again
                    daysChecked++; 
                    nextDayTimestamp = addDays(nextDayTimestamp, 1, timezone);
                    nextDay = getWeekday(nextDayTimestamp, false, timezone);
                }
            }
        };
        updateStatus();
        //schedule the open check for the next minute
        const interval = setInterval(updateStatus, 60000);
        return () => clearInterval(interval);
    }, [openingHours, timezone, orderCutoff]);

    return { isOpen, nextOpeningTime, nextClosingTime };
};

export default useIsOpen;