import { DOMINICA_TIMEZONE } from "../../constants/datetime";
import {getRelativeWeekdayString, timestampToDateString, convert24HrTimeFormatTo12HrTime} from "../../utils/datetimeUtils"
import {capitalize} from "../../utils/stringUtils"
import useIsOpen from "../../hooks/useIsOpen";

const IsOpen = ({ openingHours = {}, timeZone = DOMINICA_TIMEZONE, lastOrderBuffer = "00:00" }) => {
    const { isOpen, nextOpeningTime, nextClosingTime } = useIsOpen(openingHours, timeZone, lastOrderBuffer);
    let statusText = ""

    if (isOpen && nextClosingTime) {
        const closingTimeString = timestampToDateString(nextClosingTime, "HH:mm")
        statusText = `Closes at ${convert24HrTimeFormatTo12HrTime(closingTimeString)}`
    } else if (!isOpen && nextOpeningTime) {
        let dayString = getRelativeWeekdayString(nextOpeningTime, Date.now())
        dayString = dayString === "today" ? "": 
                    dayString !== "tomorrow" ? capitalize(dayString) : dayString
        const openingTimeString = timestampToDateString(nextOpeningTime, "HH:mm")

        statusText = `Opens${dayString ? ` ${dayString}` : ""} at ${convert24HrTimeFormatTo12HrTime(openingTimeString)}`   
    }         
    return (
        <div className="font-size-14px">
            {isOpen ? (
                <div>
                    <span className="badge success">Open</span> {statusText}
                </div>
            ) : (
                <div>
                    <span className="badge error">Closed</span> {statusText}
                </div>
            )}
        </div>
    );
};

export default IsOpen;
