import React from "react";
import Icon from "../Icon";
import styles from "./IconButton.module.css"

const IconButton = ({icon="", label="", onClick=()=>{}, containerStyle={}, buttonStyle={}, size="large"}) => {
    const sizeStyle = size === "small" ? {width: 30, height: 30} : {}
    return (
        <div onClick={onClick} style={containerStyle} className="display-flex flex-direction-column align-items-center">
            <div style={{
                ...sizeStyle,
                ...buttonStyle
            }} className={styles.button}>
                <Icon icon={icon}/>
            </div>
            <label className={styles.label}>{label}</label>
        </div>
    )
}

export default IconButton