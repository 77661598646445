import React, {useState,} from "react"

import PayeeCatalogForm from "../PayeeCatalogForm"
import {v4 as uuid4} from 'uuid'

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const CreatePayeeCatalogForm = ({
    payeeId,
    transactionLimitXcd,
    title="Add New Catalog",
    buttonText="Create Catalog",
    defaultValues={},
    postSubmit=()=>{}, 
    onCancel=()=>{}, 
    onSuccess=()=>{},
    actions,

}) => {
    const[payeeCatalogId] = useState(uuid4())
    const handleSubmit = async (
        name,
        handleId,
        headerImage,
        catalogType,
        description,
        minimumSpendXcd,
        payeeProductsByCategoryId,
        categories,
        settings,
        isActive,
        handleSubmitSuccess,
        handleSubmitError
    ) => {
        return await actions.fetchCreatePayeeCatalog(
                payeeCatalogId,
                payeeId,
                name,
                handleId,
                headerImage,
                catalogType,
                description,
                minimumSpendXcd,
                payeeProductsByCategoryId,
                categories,
                settings,
                isActive,
                handleSubmitSuccess,
                handleSubmitError
        )
    }
    return (
        <PayeeCatalogForm 
            title={title}
            defaultValues={defaultValues}
            buttonText={buttonText}
            onSubmit={handleSubmit}
            postSubmit={postSubmit}
            onCancel={onCancel}
            onSuccess={onSuccess}
            actions={actions}
            payeeId={payeeId}
            transactionLimitXcd={transactionLimitXcd}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
  
export default connect(null, mapDispatchToProps)(CreatePayeeCatalogForm)