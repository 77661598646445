import React, {useState, useEffect} from "react"
import {useParams} from "react-router-dom"

import NotFound from "../../../containers/NotFound";
import ProductCatalog from "../../../containers/ProductCatalog";

import {Helmet} from "react-helmet";

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../../actions"


const Catalog = ({payeeCatalogs, payees, actions}) => {
    const params = useParams()
    const {catalogHandleId, payeeHandleId} = params
    const payeeId = payees.payeeIdByHandleId[payeeHandleId]
    //listen to catalog indicated by the catalogId from the url params
    const [catalogListener, setCatalogListener] = useState(()=>()=>{})
    //listen to catalog indicated by the catalogId from the url params
    const [catalogLoaded, setCatalogLoaded] = useState(null)
    const payee = payees.payeesById[payeeId]
    const catalogId = payeeId && catalogHandleId && payeeCatalogs.payeeCatalogIdByPayeeCatalogHandleId[payeeId] ? 
                        payeeCatalogs.payeeCatalogIdByPayeeCatalogHandleId[payeeId][catalogHandleId]
                         :
                         ""
    const catalog = payeeCatalogs.payeeCatalogsById[catalogId]
    //listen to the catalog anytime the catalogHandleId changes
    const listenToCatalog = async () => {
        if (!payeeId || !catalog) actions.toggleLoading(true)
        actions.httpFetchSavePayeeCatalogByHandleId(catalogHandleId, payeeId, () => setCatalogLoaded(true))
        const newCatalogListener = await actions.fetchSubscribeToPayeeCatalogByHandleId(catalogHandleId, payeeId, () => setCatalogLoaded(true))
        setCatalogListener(() => newCatalogListener)
    }
    useEffect(() => {
        if (payeeId && catalogHandleId) listenToCatalog()
        return () => {
            if (typeof catalogListener === "function") catalogListener()
        }
    }, [catalogHandleId, payeeId])
    //listen to payee indicated by the payeeHandleId from the url params
    const [payeeListener, setPayeeListener] = useState(()=>()=>{})
    //track loading of payee to know if payee does not exist or is just not yet loaded
    const [payeeLoaded, setPayeeLoaded] = useState(null)
    //listen to they payee anytime the payee handle id changes
    const listenToPayee = async () => {
        //listen to payee by also load it directly for speed
        if (!payee) actions.toggleLoading(true)
        actions.httpFetchSaveMerchantPayeeByHandleId(payeeHandleId, () => setPayeeLoaded(true))
        const newPayeeListener = await actions.fetchSubscribeToMerchantPayeeByHandleId(payeeHandleId, () => setPayeeLoaded(true))
        setPayeeListener(() => newPayeeListener)
    }
    useEffect(() => {
        if (payeeHandleId) listenToPayee()
        return () => {
            if (typeof payeeListener === "function") payeeListener()
        }
    }, [payeeHandleId])
    //listen to products attached to the catalog
    const [productsListener, setProductsListener] = useState(()=>()=>{})
    const listenToProducts = async () => {
        actions.httpFetchSavePayeeProductsForPayeeCatalog(catalogId)
        const newProductsListener = await actions.fetchSubscribeToPayeeProductsForPayeeCatalog(catalogId)
        setProductsListener(() => newProductsListener)
    }
    useEffect(() => {
        if (catalogId) listenToProducts()
        return () => {
            if (typeof productsListener === "function") productsListener()
        }
    }, [catalogId])
    useEffect(() => {
        if (catalogLoaded && payeeLoaded) {
            actions.toggleLoading(false)
        }
    }, [catalogLoaded, payeeLoaded])
    useEffect(() => {
        return () => {
            actions.toggleLoading(false)
        }
    }, [])
    
    // const invoiceRetrieved = Boolean(invoice)
    //Once we have loaded the invoice and confirmed that it exists
    //1. add an invoice view
    //2. store the state of it's receiptTds array, when it was loaded
    // const receiptIds = invoice && invoice.receiptIds ? invoice.receiptIds : []
    // const [receiptIdsAtLoad, setReceiptIdsAtLoad] = useState(null) 
    // useEffect(() => {
    //     if (invoiceLoaded && invoiceRetrieved && device.id){
    //         actions.fetchViewInvoice(invoiceId)
    //         setReceiptIdsAtLoad(invoice && invoice.receiptIds ? invoice.receiptIds : [])
    //     }
    // }, [invoiceLoaded, invoiceRetrieved, invoiceId, device.id])
    //handle card payments 
    // const [cardPaymentModalOpen, setCardPaymentModalOpen] = useState(false)
    
    //redirect from the invoice page to the receipt page if
    //  1. the invoice has the current status of PAID
    //  2. the invoice's receiptIds changes to include another receipt. This captures both PAID and PARTIALLY_PAID situations
    // const [redirectToReceiptId, setRedirectToRecieptId] = useState(null)
    // useEffect(() => {
    //     if (
    //         (
    //             receiptIdsAtLoad && //invoice has been loaded and receipt ids at load tracked
    //             receiptIds.length > receiptIdsAtLoad.length
    //         ) ||
    //         (invoice && invoice.currentStatus === INVOICE_STATUS_PAID)
    //     ){
    //         setRedirectToRecieptId([...receiptIds].pop())
    //     }
    // }, [receiptIds.length])
    // if (redirectToReceiptId) return <Navigate replace to={`/${payeeHandleId}/r/${redirectToReceiptId}`}/>
    if (catalogLoaded && !catalog) return <NotFound />
    if (payeeLoaded && !payee) return <NotFound />
    if (catalog && !catalog.isActive) return <div className="margin-auto margin-top-1em width-fit-content card bold">{catalog.name} is not available</div>
    //if the payee and catalog have not yet been loaded, and one or both is falsy
    if (!catalog || !payee) return ""
    if (!payee.isActive) return <div>payee is not active</div>
    // const {totalXcd, senderFeeXcd, totalPlusFeesXcd, reason, reference, invoiceNumber, billToCustomer={}} = invoice
    return (
        <div>
            <Helmet>
                <title>{payee.name}'s Store </title>
            </Helmet>
            <div className="">
                <ProductCatalog
                    payeeId={payee.id}
                    payeeName={payee.name}
                    payeeLogoImageUrl={payee.logoImageUrlMed}
                    payeeCatalogId={catalog.id}
                />
                {/* <div className="card display-width-400px card-shadow">
                    <div className="flex-center"><AvatarIcon name={payee.name} imageUrl={payee.logoImageUrlMed}/></div> 
                    <div className="h2 margin-bottom-05em text-align-center">Pay {payee.name}</div>
                    <div className="amount big">EC $<AmountSmallAfterDecimal amount={totalXcd}/></div>
                    {Number(senderFeeXcd) > 0 ? <div>Processing Fee: <span className="font-weight-600 amount">EC $<AmountSmallAfterDecimal amount={senderFeeXcd}/></span></div> : null}
                    <div className="label margin-top-05em">For</div>
                    <div className={`${styles.reason} font-size-14px`}>{reason}</div>
                    {invoiceNumber ? <div className="">Invoice {invoiceNumber}</div> : null }
                    <button 
                        className="button gradient width-100pct margin-top-1em"
                        onClick={() => setCardPaymentModalOpen(true)}
                    >
                        Pay Now
                    </button>
                </div>
                <div className="margin-top-1em card display-width-400px card-shadow font-size-14px color-grey">
                    <div>{payee.name} uses Shopdm Pay to accept card payments securely online.</div>
                    <div  className="margin-top-05em">Shopdm Pay is a product of Dom Software Ltd, a company registered in the Commonwealth of Dominica (No. 2019/C0074)</div>
                </div> */}
            </div>

        </div>
    )
}

const mapStateToProps = state => ({
    payeeCatalogs: state.payeeCatalogs,
    payees: state.payees,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Catalog)