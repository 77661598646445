import React, {useState, useEffect} from "react"
import Icon from "../../../components/Icon"
// import DateRangePicker from "../../../components/DateRangePicker";

import PayeeOrderTable from "../../../containers/PayeeOrderTable"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../../actions"

import {Helmet} from "react-helmet";
import { useOutletContext } from 'react-router-dom';

// import { DAY_IN_MILLISECONDS } from "../../../constants/datetime";
// import {getTimestampForStartOfDay} from "../../../utils/datetimeUtils"

const orderViews = [
    {id: "live", label:"Live Orders"},
    {id: "all", label:"Order History"},
]
const PayeeOrders = ({payeeOrders, payees, actions}) => {
    const {payeeId} = useOutletContext()

    //  /**subscribe to a listener for the payee's payee orders **/
    // //from 30 days ago
    // const [fromDate, setFromDate] = useState(getTimestampForStartOfDay(Date.now() - (DAY_IN_MILLISECONDS * 30)))
    // //to the end of today
    // const [toDate, setToDate] = useState(getTimestampForStartOfDay(Date.now()) + DAY_IN_MILLISECONDS - 1)
    // //update the toDate and fromDate whne the DateRangePicker is toggled
    // const handleChangeDateRange = dateRange => {
    //     const {fromDate, toDate} = dateRange
    //     setFromDate(fromDate)
    //     setToDate(toDate)
    // }
    const [orderView, setOrderView] = useState("live")
    const [payeeOrdersListener, setPayeeOrdersListener] = useState(() => () => {})
    //listen to any payee orders for this payee made in the specified date range
    const listenToPayeeOrders = async () => {
        const newPayeeOrdersListener = await actions.fetchSubscribeToPaidPayeeOrders(
            payeeId, 
            // fromDate,
            // toDate
        )
        setPayeeOrdersListener(() => newPayeeOrdersListener)
    }
    useEffect(() => {
        listenToPayeeOrders()
        return () => {
            if (typeof payeeOrdersListener === "function") payeeOrdersListener()
        }
    }, 
    // [payeeId, fromDate, toDate]
    [payeeId]
    )
    const payeeOrdersInDateRange = Object.values(payeeOrders.payeeOrdersById)
                                           .filter(p => p.payeeId === payeeId &&
                                                        p.paidAt && 
                                                        (orderView === "all" || !p.closedAt)
                                                        // i.createdAt >= fromDate && i.createdAt <= toDate
                                                  )
    const payee = payees.payeesById[payeeId]
    if (!payee) return ""
    return (
        <div>
            <Helmet>
                <title>Orders - Shopdm Pay </title>
            </Helmet>
            <div className="h1 icon-left"><Icon icon="shopping-cart"/>Orders</div>
            <div className="margin-top-1em">
                {/* <DateRangePicker 
                    dateRange={{fromDate, toDate}}
                    onChange={handleChangeDateRange}   
                /> */}
                <div className="display-flex">
                    {
                        orderViews.map(v => {
                            const selected = v.id === orderView
                            return <div 
                                        className={`badge font-size-24px padding-1em margin-right-1em cursor-pointer ${selected? "dark" : ""}`} 
                                        key={v.id}
                                        onClick={() => setOrderView(v.id)}
                                    >
                                            {v.label}
                                    </div>
                    })
                    }
                </div>
                <PayeeOrderTable 
                    payeeOrdersArray={payeeOrdersInDateRange}
                    title="Orders"
                    payeeName={payee.name}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    payeeOrders: state.payeeOrders,
    payees: state.payees
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PayeeOrders)