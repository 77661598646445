import CheckBox from "../../components/CheckBox"
import ProductDetails from "../ProductDetails"
import styles from "./ProductSelectionListItem.module.css"
/**
 * Select products for some purpose, e.g. to add them to a sublist
 *  
 */
const ProductSelectionListItem = ({
    product,
    defaultActiveVariantId="",
    onChange=()=>{},
    selected=false
}) => {
    if (!product) return null
    return (
        <div className={`${styles.container}`}>
            <div>
                <CheckBox checked={selected} onChange={onChange}/>
            </div>
            <div className={styles.productDetails}>
            <ProductDetails 
                product={product}
                defaultActiveVariantId={defaultActiveVariantId}
            />
            </div>
            
        </div>
    )
}

export default ProductSelectionListItem