import {getFunctions, httpsCallable} from "firebase/functions";
import {firebaseApp} from "../config/firebase"
import {getFirestore, collection, query, getDoc, doc, setDoc, where, onSnapshot, updateDoc, serverTimestamp} from "firebase/firestore";

import {isCallableError, isInternalCallableError, logError} from "../utils/errorHandlingUtils"
import {DAY_IN_MILLISECONDS} from "../constants/datetime"
import {PAYEE_ORDER_STATUS_CREATED} from "../constants/payeeOrder"
import {toggleLoading} from "./system";

export const SAVE_PAYEE_ORDERS = 'SAVE_PAYEE_ORDERS'
export const CREATE_PAYEE_ORDER = 'CREATE_PAYEE_ORDER'
export const UPDATE_PAYEE_ORDER = 'UPDATE_PAYEE_ORDER'

export const savePayeeOrders = (payeeOrders, payeeId, lastLoadedAt=0) => {
    return {
        type: SAVE_PAYEE_ORDERS,
        payload: {
            payeeOrders,
            payeeId,
            lastLoadedAt
        }
    }
}

export const createPayeeOrder = payeeOrder => {
    return {
        type: CREATE_PAYEE_ORDER,
        payload: {
            payeeOrder
        }
    }
}

export const updatePayeeOrder = (payeeOrderId, update={}) => {
    return {
        type: UPDATE_PAYEE_ORDER,
        payload: {
            payeeOrderId,
            update
        }
    }
}

export const callCreatePayeeOrder = (
    id,
    totalXcd,
    lineItemsById,
    payeeId,
    payeeCatalogId,
    payeeCatalogName,
    payeeCatalogType,
    cartId,
    payerData,
    onSuccess = () => {
    },
    onError = () => {
    }
) => {
    const functions = getFunctions();
    return async (dispatch, getState) => {
        const {user, device} = getState()
        const payeeOrder = {
            id,
            totalXcd: Number(totalXcd),
            lineItemsById,
            payeeId,
            payeeCatalogId,
            payeeCatalogName,
            payeeCatalogType,
            cartId,
            currentStatus: PAYEE_ORDER_STATUS_CREATED,
            statusHistory: {
                [PAYEE_ORDER_STATUS_CREATED]: Date.now()
            },
            payerData,
            closedAt: null,
            createdAt: Date.now(),
            createdByUserId: user.id ? user.id : null,
            createdByDeviceId: device.id ? device.id : null,
        }
        try {
            const createPayeeOrderResponse = await httpsCallable(functions, 'createPayeeOrder')({
                // token: "token",
                payeeOrder
            });

            const {success, createdPayeeOrder} = createPayeeOrderResponse.data;
            if (!success) {
                return {success: false};
            }
            // when the createPayeeOrder callable resolves with success: true,
            // it returns the payee order enriched with feesData needed for further payment processing
            dispatch(createPayeeOrder(createdPayeeOrder));
            onSuccess(createdPayeeOrder.id);
            return {success: true, payeeOrder: createdPayeeOrder};
        } catch (error) {
            // OTP creation can throw a variety of different exceptions.
            // The callable errors can vary from invalid data being sent to BE (shouldn't happen considering UI checks that) to invalid internal database state
            // All of those exceptions are logged and notified about into slack (except for exceeding the limits).
            // Internal errors would include absence of limits on payee as well as any unexpected error in a callable.
            // Therefore, only internal error will result in an additional sentry log to avoid spamming Sentry.
            // if (!isCallableError(error) || isInternalCallableError(error)) {
                logError(
                    `${error.message} action > payeeOrders > callCreatePayeeOrder: Failed to create payee order ${JSON.stringify(payeeOrder)}`
                );
            // }
            // dispatch(handleOneTimePaymentCreateError(isCallableError(error) ? error : 'Unknown error'))
            return  {success: false, error: error.message};
        } finally {
            dispatch(toggleLoading(false))
        }
    }
}

export const fetchSubscribeToPayeeOrder = (
    payeeOrderId = "",
    onLoad = () => {
    }
) => {
    /**
     * Purpose: retrieve one payeeOrder from the firestore database
     * Note: the onSnapshot below watches for changes on the server
     */
    if (!payeeOrderId) return () => {
    }
    const firestore = getFirestore(firebaseApp)
    const payeeOrdersRef = doc(firestore, "payeeOrders", payeeOrderId)
    return async dispatch => {
        try {
            const payeeOrdersListener = await onSnapshot(payeeOrdersRef,
                async docRef => {
                    if (!docRef.exists()) {
                        onLoad()
                        return
                    }
                    const payeeOrder = {...docRef.data()};
                    dispatch(savePayeeOrders([payeeOrder], payeeOrder.payeeId))
                    onLoad()
                })
            return payeeOrdersListener
        } catch (e) {
            const message = `action > payeeOrders > fetchSubscribeToPayeeOrder: Failed to save payeeOrder ${payeeOrderId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const fetchSubscribeToPaidPayeeOrders = payeeId => {
    /**
     * Purpose: retrieve the payee's paid payee orders from the firestore database
     * Note: the onSnapshot below watches for changes to the center on the server
     */
    const firestore = getFirestore(firebaseApp)
    const payeeOrdersRef = query(collection(firestore, "payeeOrders"),
        // where("paidAt", "!=", null),
        where("payeeId", "==", payeeId))

    return async dispatch => {
        try {
            const payeeOrdersListener = await onSnapshot(payeeOrdersRef,
                querySnapshot => {
                    //get an array of payeeOrders from the snapshot
                    const payeeOrders = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayeeOrders(payeeOrders, payeeId, Date.now()))
                }
            )
            return payeeOrdersListener
        } catch (e) {
            const message = `action > payeeOrders > fetchSubscribeToOpenPayeeInvoices: Failed to save paid payee orders for payee ${payeeId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const fetchUpdatePayeeOrder = (
    id,
    update={},
    onSuccess=()=>{},
    onError=()=>{}
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payeeOrdersRef = doc(firestore, "payeeOrders", id)
    return async (dispatch, getState) => {
        try{
            const {user, payeeOrders} = getState()
            const payeeOrder = payeeOrders.payeeOrdersById[id]
            if (!payeeOrder) throw new Error(`Payee Order ${id} not found in redux`)
            update.lastUpdatedByUserId = user.id
            update.lastUpdatedAt = Date.now()
            update.lastUpdatedAtTimestamp = serverTimestamp()
            await setDoc(payeeOrdersRef, update, {merge:true})
            dispatch(updatePayeeOrder(id, update))
            onSuccess(update)
            return true
        } catch (e){
            const message = `action > payeeOrders > fetchUpdatePayeeOrder: Failed to update Payee Order ${id} with update ${JSON.stringify(update)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(update)
            return false
        }
    }
}