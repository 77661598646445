import React from "react"

import PayeeCatalogForm from "../PayeeCatalogForm"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const UpdatePayeeCatalogForm = ({
    payeeCatalogId,
    payeeId,
    transactionLimitXcd,
    title="Update Catalog",
    buttonText="Update Catalog",
    defaultValues={},
    postSubmit=()=>{}, 
    onCancel=()=>{}, 
    onSuccess=()=>{},
    actions,
    payeeCatalogs
}) => {
    const payeeCatalog = payeeCatalogs.payeeCatalogsById[payeeCatalogId]
    if (!payeeCatalog) return
    const handleSubmit = async (
        name,
        handleId, //cannot be edited
        headerImage,
        catalogType,
        description,
        minimumSpendXcd,
        payeeProductsByCategoryId,
        categories,
        settings,
        isActive,
        handleSubmitSuccess,
        handleSubmitError
    ) => {
        return await actions.fetchUpdatePayeeCatalog(
                payeeCatalogId,
                {
                    name,
                    headerImage,
                    catalogType,
                    description,
                    minimumSpendXcd,
                    payeeProductsByCategoryId,
                    categories,
                    settings,
                    isActive,
                },
                handleSubmitSuccess,
                handleSubmitError
        )

    }
    return (
        <PayeeCatalogForm 
            title={title}
            defaultValues={{
                ...payeeCatalog,
                ...defaultValues
            }}
            buttonText={buttonText}
            onSubmit={handleSubmit}
            postSubmit={postSubmit}
            onCancel={onCancel}
            onSuccess={onSuccess}
            actions={actions}
            payeeId={payeeId}
            payeeCatalogId={payeeCatalogId}
            transactionLimitXcd={transactionLimitXcd}
        />
    )
}

const mapStateToProps = state => ({
    payeeCatalogs: state.payeeCatalogs
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
  
export default connect(mapStateToProps, mapDispatchToProps)(UpdatePayeeCatalogForm)