import React, {useState, useEffect} from "react"
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal"
import IncrementInput from "../../components/IncrementInput"
import ImageWithPlaceholder from "../../components/ImageWithPlaceholder"
import ProductVariantPicker from "../../components/ProductVariantPicker"
import ProductVariantOption from "../../components/ProductVariantOption"
import SoldOut from "../../components/SoldOut"
import styles from "./ProductListItem.module.css"
import {DISPLAY_TYPE_TILE, DISPLAY_TYPE_ROW} from "../../constants/interface"

import { truncateStringWithEllipsis } from "../../utils/stringUtils"

import * as actions from "../../actions"
import { useSelector, useDispatch } from "react-redux";

const ProductListItem = ({
    product={},
    defaultActiveVariantId="", 
    activeVariantEditable=true, //used to stop changes to selected variant in cart 
    displayType=DISPLAY_TYPE_ROW,
    payeeCatalogId="",
    cartId=""
}) => {
    const {
        id, name="", price=0, description="", 
        defaultVariantId="", variantsById={}, hasVariants=false,  
        payeeId=""
    } = product
    const dispatch = useDispatch();

    //find the cart based on the payeeId
    const [activeVariantId, setActiveVariantId] = useState(
        //if a default selected 
        defaultActiveVariantId && variantsById[defaultActiveVariantId] ? 
        defaultActiveVariantId
        :
        defaultVariantId
    )
    const cartItem = useSelector(state => (
        state.carts.cartsById[cartId] && state.carts.cartsById[cartId].lineItemsById[activeVariantId]
        ) || null,
        //only rerender if the quantity of the cart item has changed
        (prev, next) => (prev && next ? prev.quantity === next.quantity : prev === next) 
    );
    const variantsInCart = useSelector(state => (
            hasVariants && 
            state.carts.cartsById[cartId] && 
            state.carts.cartsById[cartId].lineItemsById &&
            Object.keys(variantsById).reduce((map, variantId) => {
                if (state.carts.cartsById[cartId].lineItemsById[variantId]) map[variantId] = true
                return map
            }, {})
        ) || {},
        //only rerender if the quantity of the cart item has changed
        (prev, next) => Object.keys(prev).length === Object.keys(next).length
    );
    //if the default active variant changes, adjust it in state
    useEffect(() => {
        if (defaultActiveVariantId && activeVariantEditable) setActiveVariantId(defaultActiveVariantId)
    }, [defaultActiveVariantId, activeVariantEditable])
    const activeVariant = variantsById[activeVariantId]
    if (!activeVariant) return null
    const {quantityInStock=0, hasLimitedStock=false, minimumOrderQty=1} = activeVariant
    const activeImageUrl = activeVariant.images && activeVariant.images[0] && activeVariant.images[0].med ?
                            activeVariant.images[0].med
                            :
                            product.imageUrl
    const handleChangeSelectedQuantity = quantity => {
        const {price, variantDetails, quantityInStock, hasLimitedStock} = activeVariant
        const item = {
            id: activeVariant.id,
            payeeProductId: product.id,
            productType: product.productType ? product.productType : "",
            price,
            name,
            imageUrl: activeImageUrl,
            variantDetails,
            hasVariants,
            payeeId,
            quantityInStock,
            hasLimitedStock,
        }
        dispatch(actions.addToCart(item, quantity, cartId, payeeCatalogId))
    }
    const selectedQuantity = cartItem ? cartItem.quantity : 0
    //change color of button when it is selected
    const backgroundColor = selectedQuantity > 0 ? "var(--success-green)" : "var(--dark-grey)"

    const displayTypeStyle = displayType === DISPLAY_TYPE_ROW ? styles.row :
                             ""
    // console.log("rendering ", name)
    return (
        <div className={styles.container}>
        <div className={`${styles.productContainer} ${displayTypeStyle}`}>
            <div className={styles.detailsContainer}>
                <div className={`${styles.image}`}>
                    <ImageWithPlaceholder src={activeImageUrl} containerClassName="productImageContainer"/>
                </div> 
                <div className={styles.textContainer}>
                    <div className={styles.name}>{truncateStringWithEllipsis(name, 35)}</div>
                    <div className="amount">EC$ <AmountSmallAfterDecimal amount={activeVariant.price}/></div>
                    <div className={styles.description}>{typeof description === "string" ? description.replaceAll("\\n", " "): ""}</div>
                </div>
            </div>
            <div>
                {
                    !hasLimitedStock ||
                    quantityInStock >= minimumOrderQty ?
                    <IncrementInput 
                        value={selectedQuantity}
                        onChange={handleChangeSelectedQuantity}
                        min={0}
                        max={hasLimitedStock ? quantityInStock : undefined}
                        buttonStyle={{backgroundColor, backgroundImage: "none"}}
                    />
                    :
                    <SoldOut />
                }
            </div>
        </div>
        {
                        hasVariants && Object.keys(variantsById).length > 1  ?
                        <div className={styles.productVariantsContainer}>
                            {
                                activeVariantEditable ?
                                <ProductVariantPicker 
                                    variantsById={variantsById} 
                                    selectedVariantId={activeVariantId}
                                    highlightVariantIdMap={variantsInCart}
                                    defaultImageUrl={activeImageUrl}
                                    onChange={selectedVariantId => setActiveVariantId(selectedVariantId)}
                                    editable={activeVariantEditable}
                                />
                                :
                                <ProductVariantOption 
                                    price={null}
                                    variantDetails={activeVariant.variantDetails}
                                    imageUrl={activeImageUrl}
                                    isInactive={false}
                                    onClick={()=>{}}
                                    editable={false}
                                />
                            }
                        </div>
                        :
                        ""
                    }
        </div>
    )
}

export default React.memo(ProductListItem)