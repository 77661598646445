import {baseUrl} from "../config/firebase"
export const compressImageFile = async (fileBlob, maxWidthOrHeight=300) => {
    //image-blob-reduce & pica docs here: https://github.com/nodeca/image-blob-reduce#readme
    const imageBlobReduce = (await import("image-blob-reduce")).default;
    const reduce = imageBlobReduce({
        pica: imageBlobReduce.pica({ features: [ 'js', 'wasm' ] }) //terser breaks imageBloblReduce after minification
    })
    const compressedFile = await reduce.toBlob(fileBlob, {max: maxWidthOrHeight})
    console.log(`Image compressed from ${fileBlob.size/1000} KBs to ${compressedFile.size/1000} KBs`)
    return compressedFile
}

export const downloadCsv = async (jsonArray=[], filename=Date.now()) => {
    try {
        const response = await fetch(`${baseUrl}v1/utils/json-to-csv`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(jsonArray),
        });

        if (!response.ok) throw new Error("Failed to fetch CSV");

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${filename}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error downloading CSV:", error);
    }
};

export const downloadExcel = async (jsonArray=[], filename=Date.now()) => {
    try {
        const response = await fetch(`${baseUrl}v1/utils/json-to-xlsx`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: jsonArray
            }),
        });

        if (!response.ok) throw new Error("Failed to fetch Excel file");

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${filename}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error downloading Excel file:", error);
    }
};