import React, {lazy, Suspense} from "react"

import Form from "../../components/Form";
import FormField from "../../components/FormField";
import Loading from "../../components/Loading";
import OrderedListInput from "../../components/OrderedListInput";
import EditableLabel from "../../components/EditableLabel";
import PayeeProductPicker from "../../containers/PayeeProductPicker";

import { Switch } from "@mui/material";

import {getHandleFromString, validateHandle} from "../../utils/payeeUtils"
import {capitalizeAllWords} from "../../utils/stringUtils"
import {isValidCurrencyValue} from "../../utils/formValidationUtils"

import {
    PAYEE_CATALOG_TYPE_DEFAULT,
    PAYEE_CATALOG_TYPE_EVENT,
    PAYEE_CATALOG_TYPE_ORDERING,
    PAYEE_CATALOG_TYPE_BOOKING,
    PAYEE_CATALOG_TYPE_SCHEDULING,
    PAYEE_CATALOG_TYPE_DELIVERY,
    PAYEE_CATALOG_DEFAULT_CATEGORY_ID
} from "../../constants/payeeCatalog"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import {v4 as uuid4} from "uuid"

import { retryLazy } from "../../utils/applicationUtils";
import ErrorBoundary from "../../components/ErrorBoundary";
import CloseButton from "../../components/CloseButton";
// Wrap your lazy loading with retry logic
const PhotoUpload = lazy(() =>
  retryLazy(() => import("../../components/PhotoUpload"))
);

const catalogTypes = [
    {id: PAYEE_CATALOG_TYPE_DEFAULT, name: "DEFAULT - Sell your products online"},
    {id: PAYEE_CATALOG_TYPE_EVENT, name: "EVENT - Sell event tickets & other addons"},
    {id: PAYEE_CATALOG_TYPE_ORDERING, name: "ORDERING - Take orders for a restaurant or store"},
    {id: PAYEE_CATALOG_TYPE_SCHEDULING, name: "SCHEDULING - Take appointments for your services"},
    {id: PAYEE_CATALOG_TYPE_DELIVERY, name: "DELIVERY - Get delivery requests to a location"},
    {id: PAYEE_CATALOG_TYPE_BOOKING, name: "BOOKING - Rent a hotel or car between two dates"},
]

class PayeeCatalogForm extends Form {

    constructor(props){
        super(props)
        //initialize the values
        let values = {...this.fields}
        //initialize the state values
        let headerImageUrl = ""
        let hasDescription = false
        let hasMinimumSpend = false
        //if default values are provided
        const {defaultValues={}} = props
        if (Object.keys(defaultValues).length > 0) {
            // filter defaultValues to include only keys present in this.fields
            const filteredDefaultValues = Object.fromEntries(
                Object.entries(defaultValues).filter(([key]) => key in this.fields)
            );
            //override the initial values with the default values
            values = {...values, ...filteredDefaultValues}
            const {minimumSpendXcd=0, description="", headerImage=null} = filteredDefaultValues
            //set hasDescription based on the presence of a description in the default values
            hasDescription = Boolean(description)
            //set hasMinimumSpend based on the presence of minimumSpendXcd in the default values
            hasMinimumSpend = Boolean(minimumSpendXcd)
            //set the state values from the default variant, if there is one
            headerImageUrl = headerImage ? headerImage.med : ""
        }

        this.state = {
            values,
            errors: {},
            headerImageUrl,
            hasDescription,
            hasMinimumSpend,
            manuallyEditHandleId: false
        }
        //handle ids can only be changed when the catalog is being created, they cannot be edited afterwards
        this.allowHandleIdEdits = Boolean(!props.payeeCatalogId)
    }

    fields = {
        name: "",
        handleId: "",
        headerImage: null,
        catalogType: PAYEE_CATALOG_TYPE_DEFAULT,
        description: "",
        minimumSpendXcd: 0,
        payeeProductsByCategoryId: {},
        categories: [],
        settings: {
            hasCategories: true,
            showSearchbar: true,
        },
        isActive: true,
    }

    static defaultProps = {
        title: "Catalog Form",
        buttonText: 'Save',
        onSubmit: ()=>{},
        onSuccess: ()=>{},
        defaultValues: {},
        payeeId: "",
        payeeCatalogId: "",
        clearFieldsOnSubmit: false
    }

    componentDidMount = async () => {
        const {payeeCatalogId="", actions} = this.props
        if (payeeCatalogId) {
            actions.httpFetchSavePayeeProductsForPayeeCatalog(payeeCatalogId)
        }
    }
    executeSubmit = async () => {
        const {onSubmit} = this.props
        let {
            name, 
            handleId,
            headerImage,
            catalogType,
            description,
            minimumSpendXcd,
            payeeProductsByCategoryId,
            categories,
            settings,
            isActive,
        }= this.state.values
        return await onSubmit(
            capitalizeAllWords(name.trim()),
            handleId,
            headerImage,
            catalogType,
            description.trim(),
            Number(minimumSpendXcd),
            payeeProductsByCategoryId,
            categories,
            settings,
            isActive,
            this.handleSubmitSuccess, 
            this.handleSubmitError
        )
    }

    validate = async () => {
        const {
            values, errors, hasDescription, hasMinimumSpend
        } = this.state
        let {
            name, handleId, catalogType, description, minimumSpendXcd, 
            headerImage, payeeProductsByCategoryId, categories=[], settings={}
        } = values
        const {hasCategories} = settings
        const categoryMap = categories.reduce((map, c) => {
            map[c.id] = c
            return map
        } , {})
        const {payeeId, payeeCatalogId="", transactionLimitXcd, actions, payeeCatalogs} = this.props
        actions.toggleLoading(true)
        name = name.trim()
        catalogType = catalogType.trim()
        description = description.trim()
        Object.keys(values).forEach(key => {
            if (!Boolean(values[key]) &&
                    key !== "isActive" &&
                    key !== "description" &&
                    key !== "minimumSpendXcd" &&
                    key !== "headerImage"
                ){
                const label = key === "catalogType" ? "Catalog Type" :
                              key === "handleId" ? "Catalog ID" :
                              key === "headerImage" ? "Header image" : 
                             capitalizeAllWords(key)
                errors[key] = `${label} is required`
            }
        })
        if (!errors["headerImage"] && headerImage && (typeof headerImage !== "object" || !headerImage.med)) errors.headerImage = "Image invalid" 
        if (!errors["name"]){
            const previousCatalog = Object.values(payeeCatalogs.payeeCatalogsById)
                                           .filter(c => c.payeeId === payeeId && c.id !== payeeCatalogId && !c.deleted)
                                           .find(c => String(c.name).trim().toLowerCase() === String(name).trim().toLowerCase())
            if (previousCatalog) errors["name"] = `Name is already used`
        }
        //validate handle and ensure uniqueness
        if (!errors["handleId"]){
            const [success, errorMessage] = validateHandle(handleId)
            if (!success) errors.handleId = errorMessage
        }
        if (!errors["handleId"] && !payeeCatalogId){ //only done during creation, not updating, since handles cant be updated
            const isUnique = await actions.fetchConfirmPayeeCatalogHandleIdUnique(handleId, payeeId)
            if (!isUnique) errors.handleId = `Catalog ID ${handleId} has already been used` 
        }
        if (!errors["catalogType"] && !catalogTypes.find(type => type.id === catalogType)) errors['catalogType'] = 'Catalog Type is invalid'
        if (!errors["minimumSpendXcd"] && isNaN(minimumSpendXcd)) errors['minimumSpendXcd'] = 'Minimum spend must be a number'
        if (!errors["minimumSpendXcd"] && minimumSpendXcd < 0) errors['minimumSpendXcd'] = 'Minimum spend cannot be less than zero'
        if (!errors["minimumSpendXcd"] && !isValidCurrencyValue(minimumSpendXcd)) errors["minimumSpendXcd"] = "Invalid currency total"
        if (!errors["minimumSpendXcd"] && transactionLimitXcd && Number(minimumSpendXcd) > Number(transactionLimitXcd)) errors["minimumSpendXcd"] = `Minimum spend is over your transaction limit $${transactionLimitXcd}. Contact Shopdm Pay team to increase`
        //validate category settings

        //if has categories is specified, prodvide at least one category
        if (!errors["categories"] && hasCategories && categories.length < 1) errors["categories"] = "Provide at least one category"
        //if it does not have categories, it cannot have categories in the list
        if (!errors["categories"] && !hasCategories && categories.length > 0) errors["categories"] = "Should not have categories"
        const payeeProductCategoryErrors = {}
        //validate the category to products map
        const categoryIdList = hasCategories ? categories.map(c => c.id) : [PAYEE_CATALOG_DEFAULT_CATEGORY_ID]

        categoryIdList.forEach(id => {
            //every category must have at least one product
            const {name:categoryName=""} = categoryMap[id] || {}
            if (!payeeProductCategoryErrors[id] && (!payeeProductsByCategoryId[id] || payeeProductsByCategoryId[id].length < 1)) {
                payeeProductCategoryErrors[id] = `Add at least one ${categoryName? `${categoryName} `: ""}product${hasCategories ? " or remove this category" : ""}`
            }  
        })
        Object.keys(payeeProductsByCategoryId).forEach(id => {
            //product category map cannot have categories that are not in the category array
            if (hasCategories && !categoryMap[id]) {
                payeeProductCategoryErrors[id] = "Category is not in category list"
            }  
            //TODO ensure the product type aligns with the category type
            //i.e. tickets can only be in an event catalog
        })
        
        if (Object.keys(payeeProductCategoryErrors).length > 0) errors["payeeProductsByCategoryId"] = payeeProductCategoryErrors
        actions.toggleLoading(false)
        if (Object.values(errors).every(error => !error)) return true
        else {
            console.error(errors)
            this.setState({errors})
            return false
        }
    }
    
    handleSubmitSuccess = payeeCatalog => {
        const {onSuccess=()=>{}} = this.props
        onSuccess(payeeCatalog.id)
    }

    handleSubmitError = object => {
        const {actions} = this.props
        if (actions) actions.toggleLoading(false)
        console.log(`${object ? object[this.identifier] : ""} could not be added`)
    }

    handleChangeName = name => {
        this.setState({
            values: {
                ...this.state.values,
                name
            },
            errors: {}
        }, () => {
                if (
                    this.allowHandleIdEdits && //when creating the catalog we create a handle id for simplicity
                    !this.state.manuallyEditHandleId //if the user is not manually editing the handle
                ){
                    this.handleChangeHandleId(name)
                }
            }
        )
    }

    handleChangeHandleId = handleId => {
        handleId = getHandleFromString(handleId)
        this.setState({
            values: {
                ...this.state.values,
                handleId
            },
            errors: {}
        })
    }

    toggleManuallyEditHandleId = manuallyEditHandleId => {
        const {name} = this.state.values
        this.setState({
            manuallyEditHandleId
        }, () => {if (!manuallyEditHandleId) this.handleChangeHandleId(name)})
    }

    handleChangeHasDescription = hasDescription => {
        const {values} = this.state
        const {description} = values
        this.setState({
            hasDescription,
            values: {
                ...values,
                description: hasDescription ? description : ""
            },
            errors: {}
        })
    }

    handleChangeHasMinimumSpend = hasMinimumSpend => {
        const {values} = this.state
        const {minimumSpendXcd} = values
        this.setState({
            hasMinimumSpend,
            values: {
                ...values,
                minimumSpendXcd: hasMinimumSpend ? minimumSpendXcd : 0
            },
            errors: {}
        })
    }

    handleChangeHeaderImage = (headerImage=null) => {
        const {values} = this.state
        this.setState({
            headerImageUrl: headerImage ? headerImage.med : "",
            values: {
                ...values,
                headerImage: headerImage
            },
            errors: {}
        })
    }

    validateCategoryName = name => {
        const {categories} = this.state.values
        let error = ""
        if (!name.trim()) return {success: false, error: "Provide a name"}
        const nameUsed = categories.find(c => c.name === name)
        if (nameUsed) error = "Already used"
        return {success: !nameUsed, error}
    }

    handleChangeCategoryName = (name, id) => {
        const isValid = this.validateCategoryName(name)
        if (!isValid.success) return
        const {values} = this.state
        let categories = [...values.categories] 
        categories = categories.map(
            c => c.id === id ? { ...c, name } : c
        )
        this.setState({
            values: {
                ...values,
                categories
            },
            errors: {}
        })
    } 

    handleRemoveCategory = id => {
        //TODO check whether category is used to categorize any products and if so, confirm
        const {values} = this.state
        const c = values.categories.find(c => c.id === id)
        const {name:categoryName=""} = c
        const payeeProductsByCategoryId= {...values.payeeProductsByCategoryId}
        if (
            payeeProductsByCategoryId[id] &&
            !window.confirm(`Are you sure you want to remove the products in the ${categoryName} category?`)
        ){
            return
        }
        const categories = values.categories.filter(c => c.id !== id)
        delete payeeProductsByCategoryId[id]
        this.setState({
            values: {
                ...values,
                categories,
                payeeProductsByCategoryId
            },
            errors: {}
        })
    } 

    handleChangeCategoryProducts = (payeeProductsList=[], categoryId) => {
        const {values} = this.state
        const {
            payeeProductsByCategoryId
        } = this.state.values
        this.setState({
            values: {
                ...values,
                payeeProductsByCategoryId: {
                    ...payeeProductsByCategoryId,
                    [categoryId]: payeeProductsList
                }
            },
            errors: {}
        })
    }

    handleChangeHasCategories = hasCategories => {
        const {values} = this.state
        const {settings, payeeProductsByCategoryId={}} = values
        if (
            !hasCategories && 
            Object.keys(payeeProductsByCategoryId).length >= 1 && !payeeProductsByCategoryId[PAYEE_CATALOG_DEFAULT_CATEGORY_ID]
            && !window.confirm("This will remove any existing products from the catalog, are you sure you want to proceed?")
        ){
            return
        }
        this.setState({
            values: {
                ...values,
                settings: {
                    ...settings, 
                    hasCategories
                },
                categories: [],
                payeeProductsByCategoryId: {}
            },
            errors: {}
        })
    } 
    render(){
        const {
            values, errors, headerImageUrl, hasDescription, hasMinimumSpend, manuallyEditHandleId
        } = this.state
        const {
            name,
            handleId,
            catalogType,
            isActive,
            description,
            minimumSpendXcd,
            settings,
            categories,
            payeeProductsByCategoryId
        } = values
        const {title, buttonText, payeeId} = this.props
        const {showSearchbar=true, hasCategories=false} = settings || {} 
        const payeeProductCategoryErrors = errors["payeeProductsByCategoryId"] || {}
        return (
            <div className="formContainer">
                <div className="formHeader">
                    <div className="h2 text-align-center">{title}</div>
                </div>
                <div className="formBody">
                    <div className="label">
                        Header Image 
                        <div className="font-size-12px font-weight-600">Tip:  For best results, use a 1200 × 400 px image (3:1 aspect ratio). Larger images will be resized automatically.</div>
                    </div>
                    <ErrorBoundary
                        errorHeading="Sorry, we could not load the photo upload. Check your internet connection and retry"
                        errorContext="PayeeProductForm PhotoUpload Lazy-Loading"
                    >
                        <Suspense fallback={<Loading />}>
                            <PhotoUpload 
                                defaultImageUrl={headerImageUrl}
                                onChange={headerImage => this.handleChangeHeaderImage(headerImage)} 
                                onClickClose={() => this.handleChangeHeaderImage(null)}
                                errorText={errors.headerImage}
                                compressImage={false}
                                buttonText="Upload Header"
                            />
                        </Suspense>
                    </ErrorBoundary>
                    <FormField 
                        value={name} 
                        label="Name"
                        onChange={e => this.handleChangeName(e.target.value)}
                        errorText={errors.name}
                    />
                    {
                        handleId || manuallyEditHandleId?
                        <div className={`font-size-12px ${manuallyEditHandleId ? "" : "display-flex"} align-items-center justify-content-space-between`}>
                            {
                                manuallyEditHandleId ?
                                <FormField
                                    label="Catalog ID" 
                                    value={handleId} 
                                    onChange={e => this.handleChangeHandleId(e.target.value)}
                                    errorText={errors.handleId}
                                />
                                :
                                <div>
                                    <div className="margin-right-05em margin-bottom-05em">Catalog ID: {handleId}</div>
                                    {errors.handleId ? <div className="errorText" >{errors.handleId}</div> : null}
                                </div>
                            }
                            {
                                //only show the option to manually edit the handle id if edits are allowed
                                this.allowHandleIdEdits ?
                                <div className="text-align-right">
                                <button 
                                    className="button small transparent color-secondary"
                                    onClick={() => this.toggleManuallyEditHandleId(!manuallyEditHandleId)}
                                >
                                    {manuallyEditHandleId ? "sync with name" : "change ID"}
                                </button>
                                </div>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                    <FormField 
                        value={catalogType} 
                        label="What type of catalog is this?"
                        type="select"
                        dataArray={catalogTypes}
                        allowBlank={false}
                        onChange={value => this.handleChangeSelect(value, "catalogType")}
                        errorText={errors.catalogType}
                    />
                    <div className="label display-flex align-items-center">
                        <Switch 
                            checked={isActive}
                            color="success"
                            onChange={() => this.handleChangeSelect(!isActive, "isActive")}
                        />
                        <div>
                            {isActive? "Catalog is active" : "Catalog is not active"} 
                        </div>
                    </div>
                    <div className="label display-flex align-items-center">
                        <Switch 
                            checked={hasDescription}
                            color="success"
                            onChange={() => this.handleChangeHasDescription(!hasDescription)}
                        />
                        <div>
                            {hasDescription? "Description is included on catalog" : "No description necessary"} 
                        </div>
                    </div>
                    {
                        hasDescription ?
                        <FormField 
                            value={description} 
                            label="Description"
                            type="textarea"
                            onChange={e => this.handleChange(e, "description")}
                            errorText={errors.description}
                        />
                        :
                        null
                    }
                    <div className="label display-flex align-items-center">
                        <Switch 
                            checked={hasMinimumSpend}
                            color="success"
                            onChange={() => this.handleChangeHasMinimumSpend(!hasMinimumSpend)}
                        />
                        <div>
                            {hasMinimumSpend? "Customers must spend at least $$ to order" : "No minimum spend"} 
                        </div>
                    </div>
                    {
                        hasMinimumSpend ?
                        <FormField 
                            value={minimumSpendXcd} 
                            label="Minimum spend (EC$)"
                            type="currency"
                            currency=""
                            currencySymbol=""
                            min={0}
                            onChange={value => this.handleChangeSelect(value, "minimumSpendXcd")}
                            errorText={errors.minimumSpendXcd}
                        />
                        :
                        null
                    }
                    <div className="label display-flex align-items-center">
                        <Switch 
                            checked={showSearchbar}
                            color="success"
                            onChange={() => this.handleChangeSelect({...settings, showSearchbar: !showSearchbar}, "settings")}
                        />
                        <div>
                            {showSearchbar? "Show search bar" : "Hide search bar"} 
                        </div>
                    </div>
                    <div className="label display-flex align-items-center">
                        <Switch 
                            checked={hasCategories}
                            color="success"
                            onChange={() => this.handleChangeHasCategories(!hasCategories)}
                        />
                        <div>
                            {hasCategories? "Organize products by category" : "Products are not categorized"} 
                        </div>
                    </div>
                    <div className="margin-bottom-05em">
                    {
                        hasCategories ? 
                        <>
                            {errors.categories ? <div className="errorText">{errors.categories}</div> : null}
                            <OrderedListInput
                                items={categories}
                                getItemOnAdd={name => ({id:uuid4(), name})}
                                label="Enter a category name, then press 'Add'"
                            
                                validate={this.validateCategoryName}
                                onChange={categories => this.handleChangeSelect(categories, "categories")}
                                renderItemContainerClassName="card padding-5px"
                                renderItem={c => (
                                    <div>
                                        <EditableLabel
                                            label={c.name}
                                            onChange={name => this.handleChangeCategoryName(name, c.id)}
                                            validate={name => this.validateCategoryName(name).success}

                                        />
                                        </div>
                                    )
                                }
                                renderItemRight={c =><CloseButton onClick={()=> this.handleRemoveCategory(c.id)}/> }

                            />
                        </>
                        :
                        null
                    }
                    </div>
                    {
                        ! hasCategories ? 
                        <>
                            {
                                payeeProductCategoryErrors[PAYEE_CATALOG_DEFAULT_CATEGORY_ID] 
                                ? <div className="errorText">{payeeProductCategoryErrors[PAYEE_CATALOG_DEFAULT_CATEGORY_ID]}</div> 
                                : null
                            }
                            <PayeeProductPicker 
                                title={<span className="font-weight-600">Products</span>}
                                payeeId={payeeId}
                                payeeProductIdsArray={
                                    payeeProductsByCategoryId[PAYEE_CATALOG_DEFAULT_CATEGORY_ID] || []
                                }
                                onChange={payeeProductsList => this.handleChangeCategoryProducts(payeeProductsList, PAYEE_CATALOG_DEFAULT_CATEGORY_ID)}

                            />
                        </>
                        :
                        categories.map(c => {
                            const {name: categoryName=""} = c
                            return (
                                <div key={`${c.id}-products`}>
                                    {payeeProductCategoryErrors[c.id] ? <div className="errorText">{payeeProductCategoryErrors[c.id]}</div> : null}
                                    <PayeeProductPicker 
                                        title={<span className="font-weight-600">{categoryName} products</span>}
                                        payeeId={payeeId}
                                        payeeProductIdsArray={
                                            payeeProductsByCategoryId[c.id] || []
                                        }
                                        onChange={payeeProductsList => this.handleChangeCategoryProducts(payeeProductsList, c.id)}

                                    />
                                </div>
                            )
                        })
                    }
                </div>
                <div className="formFooter">
                    <button className="button secondary width-100pct" onClick={this.handleSubmit}>{buttonText}</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    payeeCatalogs: state.payeeCatalogs
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PayeeCatalogForm)