import React, {useState, useEffect, useMemo, useCallback} from "react"
import ProductList from "../ProductList"
import AvatarIcon from "../../components/AvatarIcon"
import CartBadge from "../../components/CartBadge"
import FormField from "../../components/FormField"
import IsOpen from "../../components/IsOpen"
import TextDisplayFormatter from "../../components/TextDisplayFormatter"
import HeaderImage from "../../components/HeaderImage"
import { AVATAR_SIZE_MEDIUM} from "../../constants/interface"
import {PAYEE_CATALOG_DEFAULT_CATEGORY_ID, PAYEE_CATALOG_TYPE_ORDERING} from "../../constants/payeeCatalog"
import { countCartItems, getCartTotal } from "../../utils/cartUtils"
import { alphabeticalSort, stringBContainsStringA } from "../../utils/stringUtils"
import {connect} from "react-redux"

import  { useNavigate} from "react-router-dom"

import styles from "./ProductCatalog.module.css"
const ProductCatalog = ({payeeCatalogId="", payeeId="", payeeName="", payeeLogoImageUrl="", payeeCatalogs, payeeProducts, carts}) => {
    const [searchTerm, setSearchTerm] = useState("")
    const payeeCatalog = payeeCatalogs.payeeCatalogsById[payeeCatalogId]
    const {
        name="", minimumSpendXcd=0, description="", settings={}, 
        headerImage=null, categories=[], payeeProductsByCategoryId={}, 
        config:catalogConfig={}, catalogType=""
    } = payeeCatalog || {}
    const {openingHours={}} = catalogConfig && catalogConfig[catalogType] || {}
    const catalogProductList = useMemo(() => {
                                    return Object.values(payeeProducts.payeeProductsById)
                                                .filter(p => p.payeeCatalogIds && 
                                                            p.payeeCatalogIds.includes(payeeCatalogId)
                                                            //remove inactive or deleted products
                                                            && !(p.isInactive || p.deleted) 
                                                            //search filtering
                                                            && (!searchTerm || stringBContainsStringA(searchTerm, p.name))
                                                        )
                                                .sort((pA, pB) => alphabeticalSort(pA.name, pB.name))
                                }, 
                                [payeeProducts.payeeProductsById, payeeCatalogId, searchTerm])
    const cartId = carts.cartIdByPayeeId[payeeId]
    const cart = carts.cartsById[cartId]
    const cartButtonLabel = useMemo(() =>`View cart (${countCartItems(cart)})`, [cart])
    //navigate to the cart
    const navigate = useNavigate();
    const handleClickViewCart = () => navigate('cart', { relative: 'path' });
    //get last item added to cart for this product. Used to default to the selected variant when visiting this page after a cart exists 
    const getLastItemAddedToCartForProduct = useCallback((p) => {
        if (!p || !cart) return ""
        const itemsInCartForProduct = Object.values(cart.lineItemsById)
                                        .filter(item => item.payeeProductId === p.id)
        if (!itemsInCartForProduct || itemsInCartForProduct.length < 1) return ""
        return itemsInCartForProduct[0].id
    }, [cart])
    const {showSearchbar=true} = settings || {}
    const [headerImageUrl, setHeaderImageUrl] = useState("")
    const getOptimizedHeaderImage = (headerImage) => {
        if (!headerImage) return "";
        const width = window.innerWidth;
        if (width <= 800) return headerImage.med || "";
        return headerImage.large || "";
    };
    useEffect(() => {
        if (!headerImage) return;
    
        const updateImage = () => {
            const newImageUrl = getOptimizedHeaderImage(headerImage);
            setHeaderImageUrl((prev) => (prev !== newImageUrl ? newImageUrl : prev));
        };
    
        updateImage(); // Set initial value
        window.addEventListener("resize", updateImage);
    
        return () => window.removeEventListener("resize", updateImage);
    }, [headerImage]);
    const catalogProductMap = useMemo(() => {
        return catalogProductList.reduce((map, p) => {
                    map[p.id] = p
                    return map
                }, {})
        }, [catalogProductList])
    const productCategories = categories.length > 0 ? categories : [{id: PAYEE_CATALOG_DEFAULT_CATEGORY_ID}]
    return (
        <div>
            <div className="columnLayout margin-auto display-flex align-items-center flex-direction-column">
                {headerImageUrl ? <><div className="margin-top-5px"></div><HeaderImage imageUrl={headerImageUrl}/></> : null}
                <div className={`display-flex align-items-center ${headerImageUrl ? styles.avatarOverlay: "margin-top-05em"}`}>
                    <AvatarIcon name={payeeName} imageUrl={payeeLogoImageUrl} size={AVATAR_SIZE_MEDIUM}/>
                    <div className="margin-left-05em">{payeeName}</div> 
                </div>
                <div className="h2 padding-5px text-align-center">{name}</div>
                {description? <div className="font-size-14px text-align-center"><TextDisplayFormatter>{description}</TextDisplayFormatter></div> : null}
                {
                    //Show ORDERING-sapecific info
                    //Todo:handle more smoothly, for different catalog types
                    catalogType === PAYEE_CATALOG_TYPE_ORDERING &&
                    typeof catalogConfig === "object" &&
                    catalogConfig[PAYEE_CATALOG_TYPE_ORDERING] ?
                    <div className="margin-top-5px">
                        <IsOpen openingHours={openingHours}/>
                    </div>
                    :
                    null
                }
            </div>
            <div className="columnLayout">
                <div className="margin-left-05em margin-right-05em display-flex justify-content-space-between align-items-center">
                    <div>
                        {
                            Number(minimumSpendXcd) > 0 ?
                            <div className="font-size-14px">Mininum spend: <span className="amount font-weight-600">EC${Number(minimumSpendXcd).toFixed(2)}</span></div>
                            :
                            null
                        }
                    </div>
                    <CartBadge cart={cart} onClick={handleClickViewCart}/>
                </div>
                <div className="margin-left-05em margin-right-05em">
                    {
                        showSearchbar ?
                        <FormField
                            value={searchTerm} 
                            onChange={e => setSearchTerm(String(e.target.value))}
                            placeholder={`Search By Name`}
                            inputIconButton={"close"}
                            onClickInputIconButton={() => setSearchTerm("") }
                        />
                        :
                        null
                    }
                </div>
                {
                    //if there is a search term, just show the matching product list
                    searchTerm ?
                    <ProductList
                        productList={catalogProductList}
                        getDefaultActiveVariantId={getLastItemAddedToCartForProduct}
                        payeeCatalogId={payeeCatalogId}
                        payeeId={payeeId}
                    />
                    :

                    productCategories.map(cat => {
                        const {name=""} = cat
                        const productsInCategoryList = payeeProductsByCategoryId[cat.id] ? 
                                                        payeeProductsByCategoryId[cat.id].filter(pId => catalogProductMap[pId])
                                                                                       .map(pId => catalogProductMap[pId])
                                                        :
                                                        [] 
                        return (
                            <div key={`category-${cat.id}`} className="margin-bottom-1em">
                                {name ? <div className="font-weight-600 font-size-24px">{name}</div> : ""}
                                <ProductList
                                    productList={productsInCategoryList}
                                    getDefaultActiveVariantId={getLastItemAddedToCartForProduct}
                                    payeeCatalogId={payeeCatalogId}
                                    payeeId={payeeId}
                                />
                            </div>
                        )
                    })
                }
                
                <div className="spacer"></div>
            </div>
            {
                cart ? 
                <div className="fixed-bottom-bar">
                    <button 
                        className="button success"
                        //navigate to cart
                        onClick={handleClickViewCart} 
                    >
                        {cartButtonLabel}
                    </button>
                </div>
                :
                null
            }
        </div>
    )
}

const mapStateToProps = state => ({
    payeeCatalogs: state.payeeCatalogs,
    payeeProducts: state.payeeProducts,
    carts: state.carts
})

export default connect(mapStateToProps)(ProductCatalog)