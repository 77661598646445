import {firebaseApp} from "../config/firebase"
import {getFunctions, httpsCallable} from "firebase/functions";
import {getFirestore, collection, query, limit,  where, onSnapshot, doc, getDoc, setDoc, serverTimestamp} from "firebase/firestore";
import {getStorage} from "firebase/storage"
import {firebaseFetch, uploadImage, deleteImages} from "../utils/firebase"
import {logError} from "../utils/errorHandlingUtils"
import {getTimestampForStartOfMonth} from "../utils/datetimeUtils"

export const SAVE_PAYEE_CATALOGS = 'SAVE_PAYEE_CATALOGS'
export const CREATE_PAYEE_CATALOG = 'CREATE_PAYEE_CATALOG'
export const UPDATE_PAYEE_CATALOG = 'UPDATE_PAYEE_CATALOG'
export const SAVE_PAYEE_CATALOG_VIEWS_BY_MONTH = 'SAVE_PAYEE_CATALOG_VIEWS_BY_MONTH'

export const savePayeeCatalogs = payeeCatalogs => {
    return {
        type: SAVE_PAYEE_CATALOGS,
        payload: {
            payeeCatalogs
        }
    }
}

export const savePayeeCatalogViewsByMonth = payeeCatalogViewsByMonth => {
    return {
        type: SAVE_PAYEE_CATALOG_VIEWS_BY_MONTH,
        payload: {
            payeeCatalogViewsByMonth
        }
    }
}

export const createPayeeCatalog = payeeCatalog => {
    return {
        type: CREATE_PAYEE_CATALOG,
        payload: {
            payeeCatalog
        }
    }
}

export const updatePayeeCatalog = (payeeCatalogId, update={}) => {
    return {
        type: UPDATE_PAYEE_CATALOG,
        payload: {
            payeeCatalogId,
            update
        }
    }
}

export const fetchCreatePayeeCatalog = (
    id,
    payeeId,
    name,
    handleId,
    headerImage,
    catalogType,
    description,
    minimumSpendXcd,
    payeeProductsByCategoryId,
    categories,
    settings,
    isActive,
    onSuccess=()=>{}, 
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const payeeCatalogsRef = doc(firestore, "payeeCatalogs", id)
    const payeeCatalog = {
        id,
        payeeId,
        name,
        handleId,
        headerImage,
        catalogType,
        description,
        minimumSpendXcd,
        payeeProductsByCategoryId,
        categories,
        settings,
        isActive,
        createdAt: Date.now(),
        createdAtTimestamp: serverTimestamp(),
        deleted: false
    }
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            //upload the header image, if provided
            if (headerImage && headerImage.file){
                const storage = getStorage(firebaseApp)
                const imagePath = `payee-catalogs/${payeeId}/${payeeCatalog.id}`
                //upload a small, med and large image for the header
                payeeCatalog.headerImage = await uploadImage(
                                                                storage, 
                                                                headerImage.file, 
                                                                imagePath, 
                                                                {
                                                                    compress: true,
                                                                    largeSize: 1200,
                                                                    medSize: 800,
                                                                    smallSize: 500
                                                                }
                                                            ) 
            }
            payeeCatalog.createdByUserId = user.id ? user.id : null
            await setDoc(payeeCatalogsRef, payeeCatalog)
            dispatch(createPayeeCatalog(payeeCatalog))
            onSuccess(payeeCatalog)
            return true
        } catch (e){
            const message = `action > payeeCatalogs > fetchCreatePayeeCatalog: Failed to create payee catalog ${JSON.stringify(payeeCatalog)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(payeeCatalog)
            return false
        }
        
    }
}

export const fetchUpdatePayeeCatalog = (
    id,
    update={},
    onSuccess=()=>{},
    onError=()=>{}
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payeeCatalogsRef = doc(firestore, "payeeCatalogs", id)
    return async (dispatch, getState) => {
        try{
            const {user, payeeCatalogs} = getState()
            const previousPayeeCatalog = payeeCatalogs.payeeCatalogsById[id]
            
            //if the header image is being updated, we must
            //1. if a previous image existed, delete it from storage and
            //2. if a new image is provided, upload to it storage
            const {headerImage} = update
            if (
                "headerImage" in update &&
                (
                    !headerImage || //the previous image was deleted
                    headerImage.file //the previous image was replaced with a new image file
                )
            ){
                const storage = getStorage(firebaseApp)
                /**1. delete the current catalog header img from the server**/

                //check whether there was a header image or null
                const {headerImage: previousHeaderImage=null, payeeId} = previousPayeeCatalog || {}
                if (previousHeaderImage){
                    await deleteImages(storage, [previousHeaderImage])
                }
                /**2. upload to storage, the new header image**/
                if (headerImage && headerImage.file){ 
                    const imagePath = `payee-catalogs/${payeeId}/${id}`
                    //uploads a small, med and large image for each image in the images array
                    update.headerImage = await uploadImage(
                                                            storage, 
                                                            headerImage.file, 
                                                            imagePath, 
                                                            {
                                                                compress: true,
                                                                largeSize: 1200,
                                                                medSize: 800,
                                                                smallSize: 500
                                                            }
                                                        ) 
                }
            }
            update.lastUpdatedByUserId = user.id
            update.lastUpdatedAt = Date.now()
            update.lastUpdatedAtTimestamp = serverTimestamp()
            await setDoc(payeeCatalogsRef, {
                ...previousPayeeCatalog,
                ...update
            })
            dispatch(updatePayeeCatalog(id, update))
            onSuccess(update)
            return true
        } catch (e){
            const message = `action > payeeCatalogs > fetchUpdatePayeeCatalog: Failed to update payee catalog ${id} with update ${JSON.stringify(update)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(update)
            return false
        }
    }
}

export const fetchDeletePayeeCatalog = (
    id,
    onSuccess=()=>{},
    onError=()=>{}
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payeeCatalogsRef = doc(firestore, "payeeCatalogs", id)
    return async (dispatch, getState) => {
        try{
            const {user, payeeCatalogs} = getState()
            const payeeCatalog = payeeCatalogs.payeeCatalogsById[id]
            if (!payeeCatalog) throw new Error(`Could not find payee catalog in redux for id ${id}`)
            //1. delete all the catalog's header image from storage
            const {headerImage=null} = payeeCatalog || {}
            if (headerImage){
                const storage = getStorage(firebaseApp)
                await deleteImages(storage, [headerImage])
            }
            //2. soft delete the catalog by updating deleted=true
            const update = {...payeeCatalog, deleted: true}
            update.deletedByUserId = user.id
            update.deletedAt = Date.now()
            update.deletedAtTimestamp = serverTimestamp()
            await setDoc(payeeCatalogsRef, {
                ...update
            })
            dispatch(updatePayeeCatalog(id, update))
            onSuccess(update)
            return true
        } catch (e){
            const message = `action > payeeCatalogs > fetchDeletePayeeCatalog: Failed to delete payee catalog ${id}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}

export const httpFetchSavePayeeCatalogByHandleId = (
    payeeCatalogHandleId = "",
    payeeId = "",
    onLoad = () => {
    }
) => {
    /**
      * Purpose: retrieve one payee catalog via its handleId from the firestore database
      */
    console.time(`httpFetchSavePayeeCatalogByHandleId ${payeeCatalogHandleId}`)
    if (!payeeCatalogHandleId || !payeeId) return false
    return async dispatch => {
        try {
            const payeeCatalogs = await firebaseFetch(
                "payeeCatalogs", 
                [
                    ["handleId", "==", payeeCatalogHandleId],
                    ["payeeId", "==", payeeId],
                    ["deleted", "==", false]
                ],
                {limit: 1}
            )
            dispatch(savePayeeCatalogs(payeeCatalogs))
            onLoad()
            console.timeEnd(`httpFetchSavePayeeCatalogByHandleId ${payeeCatalogHandleId}`)
            return true
        } catch (e){
            const message = `action > payees > httpFetchSavePayeeCatalogByHandleId: Failed to save payee with handle ${payeeCatalogHandleId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToPayeeCatalogByHandleId = (
    payeeCatalogHandleId = "",
    payeeId = "",
    onLoad = () => {
    }
) => {
    /**
     * Purpose: retrieve one payee catalog via its handleId from the firestore database
     * Note: the onSnapshot below watches for changes on the server
     */
    if (!payeeCatalogHandleId || !payeeId) return () => {
    }
    const firestore = getFirestore(firebaseApp)
    const payeeCatalogsRef = query(collection(firestore, "payeeCatalogs"), 
                                where("handleId", "==", payeeCatalogHandleId),
                                where("payeeId", "==", payeeId),
                                where("deleted", "==", false),
                                limit(1)
                                )
    return async dispatch => {
        try {
            const payeeCatalogsListener = await onSnapshot(payeeCatalogsRef, 
                querySnapshot => {
                    //get an array of payee catalogs from the snapshot
                    const payeeCatalogs = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayeeCatalogs(payeeCatalogs))
                    onLoad()
                } )
            return payeeCatalogsListener
        } catch (e) {
            const message = `action > payeeCatalogs > fetchSubscribeToPayeeCatalogByHandleId: Failed to save payee catalog with handle ${payeeCatalogHandleId} from payee ${payeeId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const fetchSubscribeToPayeeCatalogs = (
    payeeId,
) => {
    const firestore = getFirestore(firebaseApp)
    const payeeCatalogsRef = query(
        collection(firestore, "payeeCatalogs"),
        where("payeeId", "==", payeeId),
    )
    return async (dispatch) => {
        try {
            const payeeCatalogsListener = await onSnapshot(payeeCatalogsRef,
                querySnapshot => {
                    //get an array of payee catalogs from the snapshot
                    const payeeCatalogs = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayeeCatalogs(payeeCatalogs))
                }
            )
            return payeeCatalogsListener
        } catch (e) {
            const message = `action > payeeCatalogs > fetchSubscribeToPayeeCatalogs: Failed to subscribe to catalogs for ${payeeId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const callViewPayeeCatalog = (
    payeeCatalogId,
    onSuccess = () => {
    },
    onError = () => {
    }
) => {
    const functions = getFunctions();
    return async (dispatch, getState) => {
        try {
            const {device, user} = getState()
            //do not track views for admins
            if (user.isAdmin) return false
            const viewPayeeCatalogResponse = await httpsCallable(functions, 'viewPayeeCatalog')({
                payeeCatalogId,
                deviceId: device.id || "",
                userId: user && user.id ? user.id : ""
            });
            if (!viewPayeeCatalogResponse.data.success) {
                return false
            }
            onSuccess(payeeCatalogId)
            return true
        } catch (e) {
            e.message = `${e.message} action > payeeCatalogs > callViewPayeeCatalog: Failed to view payee catalog ${payeeCatalogId}`
            logError(e)
            onError(e)
            return false
        }
    }
}

export const fetchSubscribeToPayeeCatalogViewsFromDate = (
    payeeCatalogId,
    payeeId,
    fromDate=Date.now()
) => {
    if (!payeeCatalogId) return () => {}
    //convert the from data to a start of month date, since the view docs are organized by month
    const startOfMonth = getTimestampForStartOfMonth(fromDate, false)
    const firestore = getFirestore(firebaseApp)
    const payeeCatalogViewsRef = query(
        collection(firestore, `payeeCatalogs/${payeeCatalogId}/views`),
        where("payeeId", "==", payeeId),
        where("monthStartAt", ">=", startOfMonth),
    )
    return async (dispatch) => {
        try {
            const payeeCatalogViewsListener = await onSnapshot(payeeCatalogViewsRef,
                querySnapshot => {
                    //get an array of payee catalogs from the snapshot
                    const payeeCatalogViewsByMonth = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayeeCatalogViewsByMonth(payeeCatalogViewsByMonth))
                }
            )
            return payeeCatalogViewsListener
        } catch (e) {
            const message = `action > payeeCatalogs > fetchSubscribeToPayeeCatalogViews: Failed to subscribe to views for payee catalog ${payeeCatalogId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const fetchConfirmPayeeCatalogHandleIdUnique = (
    handleId,
    payeeId,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const payeeCatalogHandleRef = doc(firestore, `payees/${payeeId}/payeeCatalogHandles`, handleId)
    return async () => {
        try {
            const payeeCatalogHandleDocRef = await getDoc(payeeCatalogHandleRef)
            return !payeeCatalogHandleDocRef.exists()
        } catch (e) {
            e.message = `${e.message} action > payees > fetchConfirmPayeeCatalogHandleIdUnique: Failed to confirm handle ${handleId} is unique`
            logError(e)
            onError(e)
            return false
        }
    }
}