import { PAYEE_CATALOG_TYPE_ORDERING } from "../constants/payeeCatalog"

export const getPayeeCatalogLink = (payeeCatalogHandleId, payeeHandleId = "") => {
    return `${window.location.origin}/${payeeHandleId}/c/${payeeCatalogHandleId}`
}

export const getOpeningHoursFromPayeeCatalog = payeeCatalog => {
    if (!payeeCatalog || typeof payeeCatalog !== "object") return {}
    const {config={}, catalogType=""} = payeeCatalog
    //TODO expand as we add opening hours to more catalog types
    if (![
        PAYEE_CATALOG_TYPE_ORDERING
    ].includes(catalogType)) return {}
    if (!config[catalogType]) return {}
    const {openingHours={}} = config && config[catalogType] || {}
    return openingHours
}