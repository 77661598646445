import React, {useState} from "react"
import styles from "./HeaderImage.module.css"

const HeaderImage = ({
    imageUrl="",
    hasShadow=false,
    children
}) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    return (
        <div 
            className={`${styles.container} ${hasShadow ? styles.hasShadow : ""} ${!imageLoaded ? styles.fallback : ""}`} 
            style={imageLoaded ? { backgroundImage: `url(${imageUrl})` } : {}}
        >
            {imageUrl && !imageLoaded && (
                <img
                    src={imageUrl}
                    alt=""
                    className={"display-none"} // Hidden image to detect errors
                    onLoad={() => setImageLoaded(true)}
                    onError={() => setImageLoaded(false)}
                />
            )}
            <div className={styles.innerContainer}>{children}</div>
        </div>
    )
}

export default HeaderImage