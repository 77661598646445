import { useState } from "react"
import CRUDList from "../../components/CRUDList"
import Modal from "../../components/Modal"
import OrderedListItem from "../../components/OrderedListItem"
import PayeeProductSelectionList from "../PayeeProductSelectionList"
import ProductDetails from "../../components/ProductDetails"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import { moveArrayElement } from "../../utils/generalUtils"

/**
 * A picker to select products for addition to a list. Products can be reordered.
 * Selection occurs in a modal that displays a ProductSelectionList
 * 
 */
const PayeeProductPicker = ({
    title="Products",
    payeeId="",
    payeeProductIdsArray = [], 
    payeeProducts,
    onChange=()=>{}
}) => {
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const payeeProductsById = payeeProductIdsArray.reduce((map, pId) => {
        const p = payeeProducts.payeeProductsById[pId]
        if (!p) return map
        map[pId] = p
        return map
    }, {})
    //used to sort the payee products
    const indexMap = Object.fromEntries(payeeProductIdsArray.map((key, index) => [key, index]));
    //called when items are added / removed via the PayeeProductSelectionList
    const handleChangeSelection = (selectedIdMap={}) => {
        //copy map, so we can delete its elements
        const map = {...selectedIdMap}
        //loop over the previously provided list, and preserve the order of these
        //list elements that remain
        let sortedList = []
        payeeProductIdsArray.forEach(pId => {
            if (map[pId]) {
                sortedList.push(pId)
                delete map[pId]
            }
        })
        //add any remaining elements in the list to the end of the array
        sortedList = [...sortedList, ...Object.keys(map)]
        console.log(selectedIdMap)
        onChange(sortedList)
    }

    const handleClickUp = i => onChange(moveArrayElement(payeeProductIdsArray, i, i-1))
    const handleClickDown = i => onChange(moveArrayElement(payeeProductIdsArray, i, i+1))
    const handleDelete = id => onChange(payeeProductIdsArray.filter(productId => productId !== id))
    return (
        <div>
            <CRUDList 
                title={title}
                placeholder={<div className="display-flex justify-content-center padding-05em">No products</div>}
                createIcon="add"
                createLabel="Add"
                objectsById={payeeProductsById}
                showDefaultRadioButton={false}
                defaultObjectId={""}
                showEditMenuItem={false}
                showDeleteMenuItem={true}
                showActionMenu={true}
                renderItem={
                    payeeProduct => <OrderedListItem
                                        onClickUp={() => handleClickUp(indexMap[payeeProduct.id]) }
                                        onClickDown={() => handleClickDown(indexMap[payeeProduct.id]) }
                                    >
                                        <ProductDetails 
                                            product={payeeProduct} 
                                            showDescription={false} 
                                            showVariants={false}
                                        />
                                    </OrderedListItem>
                              
                           
                }
                renderItemContainerClassName="card margin-bottom-05em padding-5px"
                sortFunction={(oA, oB) => indexMap[oA.id] - indexMap[oB.id]}
                handleClickAddButton={() => setCreateModalOpen(true)}
                handleClickDeleteMenuItem={handleDelete}
            />
            {
                createModalOpen ?
                <Modal
                    isOpen={Boolean(createModalOpen)}
                    closeModal={() => setCreateModalOpen(false)}
                    showCloseButton={true}
                    className="height-fit-content max-height-100pct"
                    overlayClassName="flex-center padding-1pct"
                    innerClassName=""
                    closeOnOverlayClick={true}
                >
                    <div className="display-width-400px">
                    <PayeeProductSelectionList 
                        payeeId={payeeId}
                        selectedIdMap={payeeProductsById}
                        onSubmit={selectedIdMap => {
                                handleChangeSelection(selectedIdMap)
                                setCreateModalOpen(false)
                            }
                        }
                    />
                    </div>
                </Modal>
                :
                null
            }
        </div>
    )
}

const mapStateToProps = state => ({
    payeeProducts: state.payeeProducts,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PayeeProductPicker)