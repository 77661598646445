import {DateTime} from "luxon"
import { DOMINICA_TIMEZONE } from "../constants/datetime"
export const timestampToDateString = (timestamp, format='yyyy-MM-dd', useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    return DateTime.fromMillis(timestamp, options).toFormat(format)
}

export const dateStringToTimestamp = (dateString, format='yyyy-MM-dd', useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    return DateTime.fromFormat(dateString, format, options).toMillis()
}

export const timestampsAreOnSameDay = (t1, t2) => {
    if (typeof t1 !== "number" || typeof t2 !== "number") return false  
    const dt1 = DateTime.fromMillis(t1)
    const dt2 = DateTime.fromMillis(t2)
    return dt1.year === dt2.year &&
           dt1.month === dt2.month &&
           dt1.day === dt2.day
}

export const getDateString = (timestamp = Date.now()) => {
    const now = new Date(timestamp)
    const year = now.getFullYear()
    const month = now.getMonth()
    const day = now.getDate()
    return `${year}-${month}-${day}`
}

export const getTimestampForStartOfDay = (timestamp=Date.now(), useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    const dt = DateTime.fromMillis(timestamp, options)
    return dt.startOf('day').ts
}

export const getTimestampForStartOfMonth = (timestamp=Date.now(), useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    const dt = DateTime.fromMillis(timestamp, options)
    return dt.startOf('month').ts
}

//Gets the day of the week
export const getWeekday = (timestamp, numeric = false, timezone = DOMINICA_TIMEZONE) => {
    const dateTime = DateTime.fromMillis(timestamp, { zone: timezone });
    return numeric ? dateTime.weekday : dateTime.toFormat("cccc").toLowerCase();
};

//Converts a "HH:mm" time string to a Unix timestamp.
export const parseTime = (timeStr, referenceTimestamp, timezone, dayOffset = 0) => {
    if (typeof timeStr !== "string" || !/^\d{2}:\d{2}$/.test(timeStr)) {
        console.warn(`Invalid time format: ${timeStr}`);
        return null; // Return null for invalid inputs
    }
    
    const [hours, minutes] = timeStr.split(":").map(Number);
    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
        console.warn(`Invalid time values: ${timeStr}`);
        return null;
    }

    const referenceDate = DateTime.fromMillis(referenceTimestamp, { zone: timezone });
    return referenceDate
        .plus({ days: dayOffset })
        .set({ hour: hours, minute: minutes, second: 0, millisecond: 0 })
        .toMillis();
};

//Adds a specified number of days to a timestamp
export const addDays = (timestamp, days, timezone = DOMINICA_TIMEZONE) => {
    return DateTime.fromMillis(timestamp, { zone: timezone }).plus({ days }).toMillis();
};

// Subtracts a time duration (e.g., "00:30") from a timestamp
export const subtractDuration = (timestamp, durationStr, timezone = DOMINICA_TIMEZONE) => {
    const [hours, minutes] = durationStr.split(":").map(Number);
    return DateTime.fromMillis(timestamp, { zone: timezone }).minus({ hours, minutes }).toMillis();
};

export const isBefore = (timestamp1, timestamp2) => {
    return timestamp1 < timestamp2;
};

export const isAfter = (timestamp1, timestamp2) => {
    return timestamp1 > timestamp2;
};

export const convert24HrTimeFormatTo12HrTime = (time) => {
    if (typeof time !== "string" || !/^\d{2}:\d{2}$/.test(time)) {
        console.warn(`Invalid time format: ${time}`);
        return time;
    }
    
    const fragments = time.split(":");
    if (fragments.length !== 2) return time;

    let hours = Number(fragments[0]);
    let minutes = fragments[1];
    let period = hours >= 12 ? "PM" : "AM";

    if (hours === 0) {
        hours = 12; // Midnight case
    } else if (hours > 12) {
        hours -= 12; // Convert 24-hour to 12-hour format
    }

    return `${hours}:${minutes} ${period}`;
};

export const getRelativeWeekdayString = (
    timestamp,
    referenceTimestamp = Date.now(),
    format = "EEEE", // Default format is full weekday name (e.g., "Monday")
    timezone = DOMINICA_TIMEZONE
) => {
    const startOfToday = getStartOfDay(referenceTimestamp, timezone);
    const startOfTomorrow = addDays(startOfToday, 1, timezone);
    const startOfYesterday = addDays(startOfToday, -1, timezone);
    
    if (isSameDay(timestamp, startOfToday, timezone)) return "today";
    if (isSameDay(timestamp, startOfTomorrow, timezone)) return "tomorrow";
    if (isSameDay(timestamp, startOfYesterday, timezone)) return "yesterday";
    
    return DateTime.fromMillis(timestamp, { zone: timezone }).toFormat(format).toLowerCase();
};

export const isSameDay = (timestamp1, timestamp2, timezone) => {
    return getStartOfDay(timestamp1, timezone) === getStartOfDay(timestamp2, timezone);
};

export const getStartOfDay = (timestamp, timezone) => {
    return DateTime.fromMillis(timestamp, { zone: timezone }).startOf("day").toMillis();
};