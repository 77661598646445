import React from "react";
import IconButton from "../IconButton";

const OrderedListItem = ({
    children,
    onClickUp=()=>{},
    onClickDown=()=>{}
}) => {
    return (
        <div className="display-flex align-items-center">
            <div className="flex-1">{children}</div>
            <div className="display-flex gap-2 margin-left-5px flex-direction-column">
                <IconButton icon="arrow-drop-up" onClick={onClickUp} buttonStyle={{width: 20, height: 20}}/>
                <IconButton icon="arrow-drop-down" onClick={onClickDown} buttonStyle={{width: 20, height: 20}}/>
            </div>
        </div>
    )
}

export default OrderedListItem