import React, {useState, useEffect, useRef} from "react"
import AddListItemInput from "../AddListItemInput"
import OrderedListItem from "../OrderedListItem"
import styles from "./OrderedListInput.module.css"
import { moveArrayElement } from "../../utils/generalUtils"
const OrderedListInput = ({
    items=[],
    getKeyFromItem=o => typeof o === "object" && o.id ? o.id : "",
    renderItem=o => `object ${o && o.id}`,
    renderItemRight=o=>null,
    onChange=()=>{},
    getItemOnAdd=value => value,
    validate,
    placeholder="",
    label="",
    renderItemContainerClassName=""
}) => {
    const [orderedItems, setOrderedItems] = useState(items)
    const lastItemRef = useRef(null);

    // Sync orderedItems when items prop changes
    useEffect(() => {
        if (JSON.stringify(items) !== JSON.stringify(orderedItems)) {
            setOrderedItems(items);
        }
    }, [items]);
    
    useEffect(() => {
        onChange(orderedItems)
    }, [orderedItems])

    const handleAddItem = (value) => {
        const newItem = getItemOnAdd(value);
        setOrderedItems((prev) => [...prev, newItem]);

        setTimeout(() => {
            if (lastItemRef.current) lastItemRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }, 100);
    };

    const handleClickUp = i => setOrderedItems(moveArrayElement(orderedItems, i, i-1))
    const handleClickDown = i => setOrderedItems(moveArrayElement(orderedItems, i, i+1))
    return (
        <div>
            <AddListItemInput 
                onChange={handleAddItem}
                validate={validate}
                label={label}
                placeholder={placeholder}
            />
            <div className={`max-height-200px overflow-y-auto ${styles.list}`}>
                {
                    orderedItems.map((item, i) => {
                        const key = getKeyFromItem(item)
                        return (
                            <div
                                ref={i === orderedItems.length - 1 ? lastItemRef : null} 
                                className={`display-flex align-items-center ${renderItemContainerClassName}`} 
                                key={key ? key : i}
                            >   
                                <div className="flex-1">
                                    <OrderedListItem 
                                        onClickUp={() => handleClickUp(i) }
                                        onClickDown={() => handleClickDown(i)}
                                    >
                                        {renderItem(item)}
                                    </OrderedListItem>
                                </div>
                                {renderItemRight(item)}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default OrderedListInput