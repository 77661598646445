/**
 *  Purpose: the modifications that are carried out on the payeeCatalog objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // payeeCatalogs:{
 //  payeeCatalogsById:{},
 //  payeeCatalogIdByPayeeCatalogHandleId: {},
 //  lastLoadedByPayeeId: {},
 //  monthlyViewsByPayeeCatalogId: {}
 // }
 
 
 const payeeCatalogsReducer = (state = initialState.payeeCatalogs, action) => {
     const {type, payload} = action;
     let payeeCatalogsById = {...state.payeeCatalogsById}
     let payeeCatalogIdByPayeeCatalogHandleId = {...state.payeeCatalogIdByPayeeCatalogHandleId}
     let lastLoadedByPayeeId = {...state.lastLoadedByPayeeId}
     let monthlyViewsByPayeeCatalogId = {...state.monthlyViewsByPayeeCatalogId}
     switch (type){
        case types.SAVE_PAYEE_CATALOGS: {
            if(typeof payload.payeeCatalogs !== "object") {
                logError(`payeeCatalogsReducer > SAVE_PAYEE_CATALOGS: payeeCatalogs payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.payeeCatalogs.forEach(payeeCatalog => {
                    //index catalog by id
                    payeeCatalogsById[payeeCatalog.id] = payeeCatalog
                    const {payeeId, handleId, deleted} = payeeCatalog
                    if (payeeId && handleId && !deleted){
                        if (!payeeCatalogIdByPayeeCatalogHandleId[payeeId]) payeeCatalogIdByPayeeCatalogHandleId[payeeId] = {}
                        payeeCatalogIdByPayeeCatalogHandleId[payeeId][handleId] = payeeCatalog.id
                    } 
                    //update the last loaded
                    lastLoadedByPayeeId[payeeCatalog.payeeId] = Date.now()
            })
            return {
                ...state,
                payeeCatalogsById,
                payeeCatalogIdByPayeeCatalogHandleId,
                lastLoadedByPayeeId
            }
        }

        case types.SAVE_PAYEE_CATALOG_VIEWS_BY_MONTH: {
            if(typeof payload.payeeCatalogViewsByMonth !== "object") {
                logError(`payeeCatalogsReducer > SAVE_PAYEE_CATALOGS: payeeCatalogs payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            const {payeeCatalogViewsByMonth=[]} = payload
            payeeCatalogViewsByMonth.forEach(monthlyViews => {
                if (!monthlyViewsByPayeeCatalogId[monthlyViews.payeeCatalogId]) monthlyViewsByPayeeCatalogId[monthlyViews.payeeCatalogId] = {}
                monthlyViewsByPayeeCatalogId[monthlyViews.payeeCatalogId][monthlyViews.id] = monthlyViews
            })
            return {
                ...state,
                monthlyViewsByPayeeCatalogId
            }
        }

        case types.CREATE_PAYEE_CATALOG: {
            if(typeof payload.payeeCatalog !== "object") {
                logError(`payeeCatalogsReducer > CREATE_PAYEE_CATALOG: payeeCatalog payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            //index catalog by id
            payeeCatalogsById[payload.payeeCatalog.id] = payload.payeeCatalog
            //index catalog by payeeId and handleId
            const {payeeId, handleId, deleted} = payload.payeeCatalog
            if (payeeId && handleId && !deleted){
                if (!payeeCatalogIdByPayeeCatalogHandleId[payeeId]) payeeCatalogIdByPayeeCatalogHandleId[payeeId] = {}
                payeeCatalogIdByPayeeCatalogHandleId[payeeId][handleId] = payload.payeeCatalog.id
            } 
            return {
                ...state,
                payeeCatalogsById,
                payeeCatalogIdByPayeeCatalogHandleId
            }
        }

        case types.UPDATE_PAYEE_CATALOG: {
            if(typeof payload.payeeCatalogId !== "string") {
                logError(`payeeCatalogsReducer > UPDATE_PAYEE_CATALOG: payeeCatalogId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.update !== "object") {
                logError(`payeeCatalogsReducer > UPDATE_PAYEE_CATALOG: update in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            const {payeeCatalogId, update} = payload
            
            return {
                ...state,
                payeeCatalogsById: {
                    ...payeeCatalogsById,
                    [payeeCatalogId]: {
                        ...payeeCatalogsById[payeeCatalogId],
                        ...update
                    }
                },
            }
        }
 
        case types.LOGOUT: {
            return initialState.payeeCatalogs
        }
        
        default: return state
     }
 }
 
 export default payeeCatalogsReducer