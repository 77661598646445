import React, {lazy, Suspense} from "react";

import Form from "../../components/Form";
import FormField from "../../components/FormField"
import CRUDList from "../../components/CRUDList";

import {capitalizeAllWords} from "../../utils/stringUtils"
import {isValidQuantity, isValidWholeNumber, validateProductVariant, isValidCurrencyValue} from "../../utils/formValidationUtils"
import Loading from "../../components/Loading";
import { Switch } from "@mui/material";
import {v4 as uuid4} from 'uuid'

import {
    PAYEE_PRODUCT_TYPE_TICKET,    
    PAYEE_PRODUCT_TYPE_SERVICE,
    PAYEE_PRODUCT_TYPE_PHYSICAL,
    PAYEE_PRODUCT_TYPE_DIGITAL,
    PAYEE_PRODUCT_TYPE_RENTAL,
} from "../../constants/payeeProducts"

import  PayeeProductVariantForm from "../PayeeProductVariantForm"

import {connect} from "react-redux"
import { retryLazy } from "../../utils/applicationUtils";
import {getHandleFromString, validateHandle} from "../../utils/payeeUtils"

import ErrorBoundary from "../../components/ErrorBoundary";
// Wrap your lazy loading with retry logic
const PhotoUpload = lazy(() =>
  retryLazy(() => import("../../components/PhotoUpload"))
);

const productTypes = [
    {id: PAYEE_PRODUCT_TYPE_SERVICE, name: "Service"},
    {id: PAYEE_PRODUCT_TYPE_TICKET, name: "Ticket"},
    {id: PAYEE_PRODUCT_TYPE_PHYSICAL, name: "Physical Product"},
    {id: PAYEE_PRODUCT_TYPE_DIGITAL, name: "Digital Product"},
    {id: PAYEE_PRODUCT_TYPE_RENTAL, name: "Rental"},
]

const NEW_VARIANT = {
    hasLimitedStock: false,
    id: "",
    images: [],
    price: 0,
    quantityInStock: 0,
    variantDetails: ""
}
class PayeeProductForm extends Form{

    constructor(props){
        super(props)
        //initialize the values
        let values = {...this.fields}
        //initialize the state values
        let productImageUrl = ""
        let hasDescription = false
        let defaultVariantHasLimitedStock = false
        let defaultVariantQuantityInStock = 0
        //if default values are provided
        const {defaultValues={}} = props
        if (Object.keys(defaultValues).length > 0) {
            // filter defaultValues to include only keys present in this.fields
            const filteredDefaultValues = Object.fromEntries(
                Object.entries(defaultValues).filter(([key]) => key in this.fields)
            );
            //override the initial values with the default values
            values = {...values, ...filteredDefaultValues}
            const {defaultVariantId="", variantsById={}, description=""} = filteredDefaultValues
            //set hasDescription based on the presence of a description in the default values
            hasDescription = Boolean(description)
            //set the state values from the default variant, if there is one
            const defaultVariant = variantsById[defaultVariantId]
            if (defaultVariant){
                const {hasLimitedStock=false, quantityInStock=0, images=[]} = defaultVariant
                defaultVariantHasLimitedStock = hasLimitedStock
                defaultVariantQuantityInStock = quantityInStock
                //if images is set on the default variant, set the product url to the first image, medium size
                if (images && images.length > 0){
                    const image = images[0]
                    if (image && image.med) {
                        productImageUrl = image.med
                        values.image = image
                    }
                }
            }
        }
        this.state = {
            values,
            errors: {},
            productImageUrl,
            hasDescription,
            defaultVariantHasLimitedStock,
            defaultVariantQuantityInStock,
            manuallyEditHandleId: false
        }
        //handle ids can only be changed when the product is being created, they cannot be edited afterwards
        this.allowHandleIdEdits = Boolean(!props.payeeProductId)
    }

    fields = {
        name: "",
        handleId: "",
        productType: "",
        price: 0,
        description: "",
        hasVariants: false,
        defaultVariantId: "",
        isInactive: false,
        variantsById: {},
        image: null
    }

    static defaultProps = {
        title: "Product Form",
        buttonText: 'Save',
        onSubmit: ()=>{},
        defaultValues: {},
        payeeId: "",
        payeeProductId: "",
        transactionLimitXcd: 0,
        clearFieldsOnSubmit: false
    }

    executeSubmit = async () => {
        const {onSubmit} = this.props
        let {
            name, 
            handleId,
            productType,
            price,
            description,
            hasVariants,
            defaultVariantId,
            isInactive,
            variantsById,
            //image is not needed since the imageUrl is the only thing that is saved on the general product,
            //all image uploads are from the variant images
        }= this.state.values
        //if there are no specified variants, then we must make the default variant from the product details
        if (Object.keys(variantsById).length === 0 || !variantsById[defaultVariantId]){
            const defaultVariant = this.getDefaultVariantFromProductDetails()
            defaultVariantId = defaultVariant.id
            variantsById[defaultVariantId] = defaultVariant
        }   
        //cycle through each variant and ensure each price and quantity is casted
        for (let variantId in variantsById){
            variantsById[variantId].price = Number(variantsById[variantId].price)
            variantsById[variantId].quantityInStock = Number(variantsById[variantId].quantityInStock)
        }
        return await onSubmit(
            capitalizeAllWords(name.trim()),
            handleId,
            productType,
            Number(price),
            description.trim(),
            defaultVariantId,
            hasVariants,
            variantsById,
            isInactive,
            this.handleSubmitSuccess, 
            this.handleSubmitError
        )
    }

    handleSubmitError = object => {
        const {actions, payeeProductId} = this.props
        const {values} = this.state
        if (actions) actions.toggleLoading(false)
        console.log(`${object ? object[this.identifier] : ""} could not be added`)
        //if the product was being created, it had no variant, but it was automatically made
        //remove the automatically added variant, to allow it to work the second time
        if (!payeeProductId && !values.hasVariants){
            this.setState({
                values: {
                    ...values,
                    variantsById: {}
                }
            })
        }
    }

    validate = async () => {
        const errors = {}
        const {
            values, hasDescription,
            defaultVariantHasLimitedStock,
            defaultVariantQuantityInStock
        } = this.state
        let {
            name, handleId, productType, price, description, hasVariants, 
            defaultVariantId, variantsById, image
        } = values
        const {payeeProducts, payeeId, payeeProductId="", transactionLimitXcd, actions} = this.props
        actions.toggleLoading(true)
        name = name.trim()
        productType = productType.trim()
        description = description.trim()
        Object.keys(values).forEach(key => {
            if (!Boolean(values[key]) &&
                    key !== "isInactive" &&
                    key !== "hasVariants" &&
                    key !== "description" &&
                    key !== "defaultVariantId"
                ){
                const label = key === "productType" ? "Product Type" :
                              key === "handleId" ? "Product ID" :
                             capitalizeAllWords(key)
                errors[key] = `${label} is required`
            }
        })
        if (!errors["image"] && (!image || typeof image !== "object" || !image.med)) errors.image = "Image invalid" 
        if (!errors["name"]){
            const previousProduct = Object.values(payeeProducts.payeeProductsById)
                                           .filter(p => p.payeeId === payeeId && p.id !== payeeProductId && !p.deleted)
                                           .find(p => String(p.name).trim().toLowerCase() === String(name).trim().toLowerCase())
            if (previousProduct) errors["name"] = `Name is already used`
        }
        //validate handle and ensure uniqueness
        if (!errors["handleId"]){
            const [success, errorMessage] = validateHandle(handleId)
            if (!success) errors.handleId = errorMessage
        }
        if (!errors["handleId"] && !payeeProductId){ //only done during creation, not updating, since handles cant be updated
            const isUnique = await actions.fetchConfirmPayeeProductHandleIdUnique(handleId, payeeId)
            if (!isUnique) errors.handleId = `Product ID ${handleId} has already been used` 
        }
        if (!errors["productType"] && !productTypes.find(type => type.id === productType)) errors['productType'] = 'Product Type is invalid'
        if (!errors["price"] && isNaN(price)) errors['price'] = 'Price must be a number'
        if (!errors["price"] && price <= 0) errors['price'] = 'Price must be more than zero'
        if (!errors["price"] && !isValidCurrencyValue(price)) errors["price"] = "Invalid currency total"
        if (!errors["price"] && transactionLimitXcd && Number(price) > Number(transactionLimitXcd)) errors["price"] = `Product price is over your transaction limit $${transactionLimitXcd}. Contact Shopdm Pay team to increase`
        if (!errors["description"] && hasDescription && !description) errors['description'] = 'Provide a description'
        if (!errors["description"] && typeof description !== "string") errors['description'] = 'Description must be a string'
        if (hasVariants && Object.keys(variantsById).length === 0) errors['hasVariants'] = "Must have at least one variant"
        if (!errors['hasVariants'] && !hasVariants && Object.keys(variantsById).length > 1) errors['hasVariants'] = "Has variants is false, but there are variants"
        if (!errors['hasVariants'] && hasVariants && !defaultVariantId) errors['hasVariants'] = "Must have a default variant"
        if (!errors['hasVariants'] && hasVariants && !variantsById[defaultVariantId]) errors['hasVariants'] = "Default variant not found"
        //for editing, already created products must always have at least the one variant
        if (payeeProductId && (!variantsById || Object.keys(variantsById).length < 1)){
            errors['hasVariants'] = "Must have one or more variants"
        }
        //for editing, already created products must always have a default variant, that is found in their variants map
        if (!errors['hasVariants'] && payeeProductId && (!defaultVariantId || !variantsById[defaultVariantId])){
            errors['hasVariants'] = "Default variant not found"
        }
      
        if (isNaN(defaultVariantQuantityInStock)) errors['defaultVariantQuantityInStock'] = "Quantity in stock must be a number"
        if (!errors['defaultVariantQuantityInStock'] && Number(defaultVariantQuantityInStock) < 0) errors['defaultVariantQuantityInStock'] = "Quantity must be more than zero"
        if (!errors['defaultVariantQuantityInStock'] && !isValidWholeNumber(defaultVariantQuantityInStock)) errors['defaultVariantQuantityInStock'] = "Quantity must be a whole number"
    
        if (!defaultVariantHasLimitedStock && defaultVariantQuantityInStock > 0) errors.defaultVariantHasLimitedStock = "Quantity in stock should not be limited"
        
        //validate the values of each variant
        const productVariantErrorsById = Object.values(variantsById).reduce((errorsById, variant) => {
            const variantErrors = validateProductVariant(variant, variantsById, transactionLimitXcd)
            //if there were errors for this variant, add them to the map, indexed by id
            if (Object.keys(variantErrors).length > 0) {
                errorsById[variant.id] = variantErrors
            }
            return errorsById
        }, {})
        if (Object.keys(productVariantErrorsById).length > 0){
            errors.productVariantErrorsById = productVariantErrorsById
        }
        actions.toggleLoading(false)
        if (Object.values(errors).every(error => !error)) return true
        else {
            console.error(errors)
            this.setState({errors})
            return false
        }
    }

    handleSubmitSuccess = payeeProduct => {
        const {onSuccess=()=>{}} = this.props
        onSuccess(payeeProduct.id)
    }

    handleChangeName = name => {
        this.setState({
            values: {
                ...this.state.values,
                name
            },
            errors: {}
        }, () => {
                if (
                    this.allowHandleIdEdits && //when creating the product we create a handle id for simplicity
                    !this.state.manuallyEditHandleId //if the user is not manually editing the handle
                ){
                    this.handleChangeHandleId(name)
                }
            }
        )
    }

    handleChangeHandleId = handleId => {
        handleId = getHandleFromString(handleId)
        this.setState({
            values: {
                ...this.state.values,
                handleId
            },
            errors: {}
        })
    }

    toggleManuallyEditHandleId = manuallyEditHandleId => {
        const {name} = this.state.values
        this.setState({
            manuallyEditHandleId
        }, () => {if (!manuallyEditHandleId) this.handleChangeHandleId(name)})
    }

    handleChangeProductImage = (image=null) => {
        const {values} = this.state
        this.setState({
            productImageUrl: image ? image.med : "",
            values: {
                ...values,
                image: image
            },
            errors: {}
        }, this.syncProductDetailsToVariantDetails)
    }

    handleChangeHasDescription = hasDescription => {
        const {values} = this.state
        const {description} = values
        this.setState({
            hasDescription,
            values: {
                ...values,
                description: hasDescription ? description : ""
            },
            errors: {}
        })
    }

    handleChangeDefaultVariantQuantityInStock = e => {
        const quantityInStock =  e.target.value
        if (!isValidQuantity(quantityInStock)) return
        this.setState({
            defaultVariantQuantityInStock: quantityInStock //not casting to number here to allow emptying of field to ""
        }, this.syncProductDetailsToVariantDetails)
    }

    handleChangeDefaultVariantHasLimitedStock = () => {
        const {defaultVariantQuantityInStock} = this.state
        const defaultVariantHasLimitedStock = !this.state.defaultVariantHasLimitedStock
        this.setState({
            defaultVariantHasLimitedStock,
            defaultVariantQuantityInStock: defaultVariantHasLimitedStock ? defaultVariantQuantityInStock : 0 
        }, 
        this.syncProductDetailsToVariantDetails)
    }

    getDefaultVariantFromProductDetails = () => {
        const {
            values,
            defaultVariantHasLimitedStock,
            defaultVariantQuantityInStock
        } = this.state
        const {price, image} = values
        return {
            ...NEW_VARIANT,
            id: uuid4(),
            images: image ? [image] : [],
            price: price,
            variantDetails: "Regular",
            hasLimitedStock: defaultVariantHasLimitedStock,
            quantityInStock: defaultVariantQuantityInStock,
            createdAt: Date.now()
        }
    }

    syncDefaultVariantToProductDetails = () => {
        const {values} = this.state
        const {variantsById, defaultVariantId} = values
        const defaultVariant = variantsById[defaultVariantId]
        //if there is not a default variant, then there is nothing to be done
        if (!defaultVariant) return
        const {
            images,
            price,
            hasLimitedStock,
            quantityInStock
        } = defaultVariant
        const image = images && images.length > 0 ? images[0] : null
        this.setState({
            values: {
                ...values,
                price,
                image
            },
            productImageUrl: image ? image.med : "",
            defaultVariantHasLimitedStock: hasLimitedStock,
            defaultVariantQuantityInStock: quantityInStock
        })
    }

    syncProductDetailsToVariantDetails = () => {
        const {values, defaultVariantHasLimitedStock, defaultVariantQuantityInStock} = this.state
        const {
            variantsById, defaultVariantId, price, image
        } = values
        const defaultVariant = variantsById[defaultVariantId]
        //if there is not a default variant, then there is nothing to be done
        if (!defaultVariant) return
        this.setState({
            values: {
                ...values,
                variantsById: {
                    ...variantsById,
                    [defaultVariantId]: {
                        ...defaultVariant,
                        price,
                        hasLimitedStock: defaultVariantHasLimitedStock,
                        quantityInStock: defaultVariantQuantityInStock,
                        images: image? [image] : []
                    }
                }
            },
        })
    }

    handleChangeHasVariants = hasVariants => {
        const {values} = this.state
        const {payeeProductId} = this.props //to tell whether a created product is being edited
        let variantsById = {}
        let  defaultVariantId = ""
        if (!payeeProductId){
            //for product creation
            if (hasVariants){
                const newVariant =  this.getDefaultVariantFromProductDetails()
                variantsById[newVariant.id] = newVariant
                defaultVariantId = newVariant.id
            } 
        } else {
            //if the product has already been made, we cannot simply delete the existing variant
            defaultVariantId = values.defaultVariantId
            variantsById = {...values.variantsById}
            if (!hasVariants) {
                //remove all variants other than the default variant, unless they have already been sold
                for (let variantId in variantsById) {
                    const variantToDelete = variantsById[variantId]
                    if (variantId !== defaultVariantId) {
                        if (variantsById[variantId].quantitySold) {
                            alert(`Cannot delete ${variantToDelete.variantDetails}, since you have already sold ${variantToDelete.quantitySold}`)
                            return
                        }
                        else delete variantsById[variantId]
                    }
                }
            } 
        }                
        this.setState({
            values: {
                ...values,
                hasVariants,
                variantsById,
                defaultVariantId
            }
        })
    }

    //handlers for individual variants
    handleChangeVariant = (value, key, id) => {
        const {values} = this.state
        let {variantsById} = values
        const variant = variantsById[id]
        let {quantityInStock} = variant
        //ensure variant details is not longer than 30 chars
        if (key === "variantDetails"){
            if (String(value).length > 30) return
        } 
        //if the price is less than zero return
        else if (key === "price") {
            if (value < 0) return 
        } else if (key === "quantityInStock") {
            if (!isValidQuantity(value)) return
        } else if (key === "hasLimitedStock"){
            if (!value) quantityInStock = 0
        }
        this.setState({
            values: {
                ...values,
                variantsById: {
                    ...variantsById,
                    [id]: {
                        ...variant,
                        quantityInStock,
                        [key]: value
                    }
                }
            },
            errors:{}
        }, 
        this.syncDefaultVariantToProductDetails) //after any edit, sync the changes of the default variant to the product
    }

    handleChangeDefaultVariant = defaultVariantId => {
        const {values} = this.state
        this.setState({
            values:{
                ...values,
                defaultVariantId
            }
        }, this.syncDefaultVariantToProductDetails)
    }

    handleAddNewEmptyProductVariant = () => {
        const {values} = this.state
        const {variantsById} = values
        const newVariant = {
            ...NEW_VARIANT,
            id: uuid4(),
            createdAt: Date.now()
        }
        this.setState({
            values: {
                ...values,
                variantsById: {
                    ...variantsById,
                    [newVariant.id]: newVariant
                },
            },
            errors:{}
        })
    }

    handleDeleteVariant = (
        variantId,
    ) => {
        const {values} = this.state
        const variantsById = {...values.variantsById}
        const variant = variantsById[variantId]
        if (variantId === values.defaultVariantId) {
            alert("Cannot delete the default variant")
            return
        } else if (variant.quantitySold) {
            alert(`Cannot delete ${variant.variantDetails}, since you have already sold ${variant.quantitySold}`)
            return
        }
        //remove variant
        delete variantsById[variantId]
        this.setState({
            values: {
                ...values,
                variantsById //
            },
            errors:{}
        })
    }

    render(){
        const {
            values, errors, productImageUrl, hasDescription,
            defaultVariantHasLimitedStock, defaultVariantQuantityInStock,
            manuallyEditHandleId
        } = this.state
        const {
            name,
            handleId,
            productType,
            price,
            isInactive,
            description,
            hasVariants,
            defaultVariantId,
            variantsById,
        } = values
        const {title, buttonText} = this.props
        return (
            <div className="formContainer">
                <div className="formHeader">
                    <div className="h2 text-align-center">{title}</div>
                </div>
                <div className="formBody">
                    <ErrorBoundary
                        errorHeading="Sorry, we could not load the photo upload. Check your internet connection and retry"
                        errorContext="PayeeProductForm PhotoUpload Lazy-Loading"
                    >
                        <Suspense fallback={<Loading />}>
                            <PhotoUpload 
                                defaultImageUrl={productImageUrl}
                                onChange={image => this.handleChangeProductImage(image)} 
                                errorText={errors.image}
                                compressImage={false}
                                onClickClose={() => this.handleChangeProductImage(null)}
                            />
                        </Suspense>
                    </ErrorBoundary>
                    <FormField 
                        value={name} 
                        label="Name"
                        onChange={e => this.handleChangeName(e.target.value)}
                        errorText={errors.name}
                    />
                    {
                        handleId || manuallyEditHandleId?
                        <div className={`font-size-12px ${manuallyEditHandleId ? "" : "display-flex"} align-items-center justify-content-space-between`}>
                            {
                                manuallyEditHandleId ?
                                <FormField
                                    label="Product ID" 
                                    value={handleId} 
                                    onChange={e => this.handleChangeHandleId(e.target.value)}
                                    errorText={errors.handleId}
                                />
                                :
                                <div>
                                    <div className="margin-right-05em margin-bottom-05em">Product ID: {handleId}</div>
                                    {errors.handleId ? <div className="errorText" >{errors.handleId}</div> : null}
                                </div>
                            }
                            {
                                //only show the option to manually edit the handle id if edits are allowed
                                this.allowHandleIdEdits ?
                                <div className="text-align-right">
                                <button 
                                    className="button small transparent color-secondary"
                                    onClick={() => this.toggleManuallyEditHandleId(!manuallyEditHandleId)}
                                >
                                    {manuallyEditHandleId ? "sync with name" : "change ID"}
                                </button>
                                </div>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                    <FormField 
                        value={productType} 
                        label="What type of product is this?"
                        type="select"
                        dataArray={productTypes}
                        onChange={value => this.handleChangeSelect(value, "productType")}
                        errorText={errors.productType}
                    />
                    <FormField 
                        value={price} 
                        label="Price (EC$)"
                        type="currency"
                        currency=""
                        currencySymbol=""
                        min={0}
                        onChange={value => this.handleChangeSelect(value, "price", this.syncProductDetailsToVariantDetails)}
                        errorText={errors.price}
                    />
                    <div className="label display-flex align-items-center">
                        <Switch 
                            checked={!isInactive}
                            color="success"
                            onChange={() => this.handleChangeSelect(!isInactive, "isInactive")}
                        />
                        <div>
                            {isInactive? "Product is not active" : "Product is active"} 
                        </div>
                    </div>
                    <div className="label display-flex align-items-center">
                        <Switch 
                            checked={hasDescription}
                            color="success"
                            onChange={() => this.handleChangeHasDescription(!hasDescription)}
                        />
                        <div>
                            {hasDescription? "Description is included on product" : "No description necessary"} 
                        </div>
                    </div>
                    {
                        hasDescription ?
                        <FormField 
                            value={description} 
                            label="Description"
                            type="textarea"
                            onChange={e => this.handleChange(e, "description")}
                            errorText={errors.description}
                        />
                        :
                        null
                    }
                    <div className="label display-flex align-items-center">
                        <Switch 
                            checked={hasVariants}
                            color="success"
                            onChange={() => this.handleChangeHasVariants(!hasVariants)}
                        />
                        <div>
                            {hasVariants? "Yes, this product has" : "No, this product does not have"} multiple options like different sizes or colors or different types of tickets
                        </div>
                    </div>
                    {errors.hasVariants ? <div className="errorText" >{errors.hasVariants}</div> : null}
                    {
                        hasVariants?
                        <div>
                            
                            <CRUDList 
                                title={<div className="h3">{Object.keys(variantsById).length} Product Options</div>}
                                placeholder={<div className="display-flex justify-content-center padding-05em">No product options</div>}
                                createIcon="add"
                                createLabel="New"
                                objectsById={variantsById}
                                showDefaultRadioButton={false}
                                defaultObjectId={defaultVariantId}
                                showEditMenuItem={false}
                                showDeleteMenuItem={true}
                                showActionMenu={true}
                                renderItem={
                                    variant => <PayeeProductVariantForm 
                                                {...variant}
                                                isDefaultVariant={variant.id === defaultVariantId}
                                                handleChange={(value, key) => this.handleChangeVariant(value, key, variant.id)}
                                                handleChangeDefaultVariant={() => this.handleChangeDefaultVariant(variant.id)}
                                                errors={
                                                    errors && 
                                                    errors.productVariantErrorsById && 
                                                    errors.productVariantErrorsById[variant.id] ? 
                                                    errors.productVariantErrorsById[variant.id] : {}
                                                }
                                            />
                                }
                                renderItemContainerClassName="card margin-bottom-05em padding-5px"
                                sortFunction={(oA, oB) => oA.createdAt - oB.createdAt}
                                handleClickAddButton={this.handleAddNewEmptyProductVariant}
                                handleClickDeleteMenuItem={this.handleDeleteVariant}
                            />
    
                        </div>
                        :
                        //If a product does not have multiple variants, we still give them the opportunity to set the
                        //default variant to one with limited stock 
                        <>
                            <div className={"styles.quantityInputs"}>
                                <div className="label display-flex align-items-center">
                                    <Switch 
                                        checked={defaultVariantHasLimitedStock}
                                        color="success"
                                        onChange={this.handleChangeDefaultVariantHasLimitedStock}
                                    />
                                    <div className="display-flex align-items-center">
                                        <div className="margin-right-05em">{defaultVariantHasLimitedStock? "Limited quantity" : "Don't limit quantity"}</div>
                                        {errors.defaultVariantHasLimitedStock ? <div className="errorText" >{errors.defaultVariantHasLimitedStock}</div> : null}
                                        {
                                            defaultVariantHasLimitedStock ?
                                            <FormField 
                                                value={defaultVariantQuantityInStock} 
                                                label="Qty in stock"
                                                onChange={this.handleChangeDefaultVariantQuantityInStock}
                                                errorText={errors.defaultVariantQuantityInStock}
                                            />
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                
                            </div>
                        </>
                    }
                </div>
                <div className="formFooter">
                    <button className="button secondary width-100pct" onClick={this.handleSubmit}>{buttonText}</button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    payeeProducts: state.payeeProducts
})

export default connect(mapStateToProps)(PayeeProductForm)