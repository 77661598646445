const StatusSelect = ({
    currentStatus,
    statusOptions,
    onChange=()=>{},
    formatStatus=text=>text,
    className="padding-5px border-radius-8px",
    getStyle=currentStatus=>({})
}) => {
    statusOptions = Array.isArray(statusOptions) ? statusOptions : [currentStatus]

    return (
        <select
            className={className}
            value={currentStatus}
            onChange={e => onChange(e.target.value)}
            style={getStyle(currentStatus)}
        >
            {
                statusOptions.map(status => {
                    if (typeof status !== "string") return null
                    return (
                        <option key={status} value={status}>
                            {formatStatus(status)}
                        </option>
                    )
                })
            }
        </select>
    )
}

export default StatusSelect