import React from "react"

import PayeeOrderListItem from "../../components/PayeeOrderListItem"
import IconButton from "../../components/IconButton";
import DropdownMenu from "../../components/DropdownMenu"

import { shortenReference } from "../../utils/interfaceUtils";
import {timestampToDateString} from "../../utils/datetimeUtils"
import {alphabeticalSort} from "../../utils/stringUtils"
import { countCartItems} from "../../utils/cartUtils"
import {isMobile} from "../../utils/deviceUtils"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {shareViaWhatsapp, copyToClipboard} from "../../utils/interactionUtils"

const PayeeOrderItemList = ({payeeOrderId="", payeeName="", payeeOrders}) => {
    const order = payeeOrders.payeeOrdersById[payeeOrderId]
    if (!order) return ""
    const {reference, lineItemsById={}, totalXcd=0, paidByPayerName="", paidAt=0, currentStatus="", payerData={}} = order
    const {phoneNumber=""} = payerData || {}
    const shortReference = shortenReference(reference)
    const handleSend = () => {
        const getMessage = () => [
            `${payeeName} Order via Shopdm Pay`,
            `Ref: *${shortReference}*`,
            "",
            "*Customer details*",
            `Name: ${paidByPayerName}`,
            `Phone: ${phoneNumber}`,
            `Status: ${currentStatus}`,
            "",
            `*Order details (${countCartItems(order)} items)*`,
            ...Object.values(lineItemsById)
                .sort((a, b) => alphabeticalSort(a.name, b.name))
                .map(i => i ? `${i.quantity}x ${i.name}${i.hasVariants ? ` (${i.variantDetails})` : ""}` : "")
        ].join("\n");
    
        const message = getMessage();
        copyToClipboard(message);
        shareViaWhatsapp(message);
    };
    const phoneNumberMenuItems = []
    phoneNumberMenuItems.push(
        {
            label: `Whatsapp ${paidByPayerName}`,
            icon: "whatsapp",
            onClick: () => shareViaWhatsapp(`Hi ${String(paidByPayerName).split(" ")[0]}, this is ${payeeName}. Your order ${shortReference},  `, phoneNumber)   
        }
    )
    if (isMobile()) phoneNumberMenuItems.push(
        {
            label: `Call ${paidByPayerName}`,
            icon: "phone",
            onClick: () => window.open(`tel:${phoneNumber}`)
        }
    )
    return (
        <div>

            <div className="margin-bottom-1em display-width-400px">
                <div className="margin-bottom-05em">
                    <div className="h1 display-flex ">
                        <span>${totalXcd} Order from {paidByPayerName}</span>
                        <div className="margin-left-05em">
                            <IconButton 
                                icon="shortcut" 
                                label="send" 
                                size="small"
                                onClick={handleSend} 
                            />
                        </div>
                    </div> 
                    <div>Reference: <span className="font-weight-600">{shortReference}</span></div>
                    <div className="margin-top-05em">Name: <span className="font-weight-600">{paidByPayerName}</span></div>
                    <div className="display-flex align-items-center">
                        Phone: 
                        <DropdownMenu 
                            MenuIcon={<span className="a font-weight-600 margin-left-05em">{phoneNumber}</span>} 
                            menuItems={phoneNumberMenuItems}
                        />
                    </div>
                    <div className="margin-top-05em">Ordered At: <span className="font-weight-600">{timestampToDateString(paidAt, "EEE, dd MMMM 'at' t")}</span></div>
                    <div>Status: <span className="font-weight-600">{currentStatus}</span></div>

                    <div className="font-weight-600 margin-top-05em">{countCartItems(order)} items</div>
                </div>
                <div>
                    {
                        Object.values(lineItemsById)
                              .sort( (iA, iB) => alphabeticalSort(iA.name, iB.name))
                              .map( i => <PayeeOrderListItem key={i.id} item={i}/>)
                    }
                </div>
                
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    payeeOrders: state.payeeOrders,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PayeeOrderItemList)