export const moveArrayElement = (arr, from, to) => {
    if (
        !Array.isArray(arr) ||
        typeof from !== "number" ||
        typeof to !== "number" 
    ) return arr
    if (to < 0 || to >= arr.length) return arr 
    arr = [...arr]
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr
}