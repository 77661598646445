import React, {useState} from "react"
import Logo from "../../images/logo100x143.png"
import AvatarIcon from "../../components/AvatarIcon";
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal";

import PayerForm from "../../forms/PayerForm";
import CustomerFeeCalculator from "../CustomerFeeCalculator";
import FacCardPaymentModal from "../FacCardPaymentModal"

import { countCartItems, getCartTotal } from "../../utils/cartUtils"
import { validatePayer } from "../../utils/payer";
import { DEFAULT_PAYER_VALUES_US } from "../../constants/payers"
import {DEBIT_OR_CREDIT_CARD_ID, PAYMENT_OBJECT_TYPE_PAYEE_ORDER} from "../../constants/payment"

import {v4 as uuid4} from 'uuid'

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const translateError = (error) => {
    return `Something went wrong. Please check your internet connection and contact the Shopdm Pay team at pay@shopdm.store if it persists`
} 
const PayeeOrderCheckout = ({
    cartId="",
    payeeId="",
    payees,
    carts,
    payeeCatalogs,
    user,
    device,
    actions
}) => {
    //collect customer data
    const [customer, setCustomer] = useState(DEFAULT_PAYER_VALUES_US)
    const [customerErrors, setCustomerErrors] = useState({})
    const handleChangeCustomerValue = (value, attr) => {
        const customerData = {...customer}
        customerData[attr] = value
        setCustomer(customerData)
        setCustomerErrors({})
        setCreateOrderError("")
    }
    //TEMP display errors from creating the order
    const [createOrderError, setCreateOrderError] = useState("")
    const payee = payees.payeesById[payeeId]
    const cart = carts.cartsById[cartId]
    //open card payment modal
    const [cardPaymentPayeeOrder, setCardPaymentPayeeOrder] = useState(null)
    if (!payee) return ""
    if (!cart) return ""
    const cartTotalXcd = getCartTotal(cart)
    const catalog = payeeCatalogs.payeeCatalogsById[cart.payeeCatalogId]
    const {name:catalogName="", catalogType=""} = catalog || {}
    const handleClickPayNow = async () => {
        setCreateOrderError("")
        const {success, errors} = validatePayer(customer)
        if (success) {
            actions.toggleLoading(true)
            //create the one time payment
            const {
                success:createOrderSuccess, 
                error:createOrderError,
                payeeOrder
            } = await actions.callCreatePayeeOrder(
                uuid4(),
                Number(cartTotalXcd),
                cart.lineItemsById,
                payeeId,
                cart.payeeCatalogId,
                catalogName,
                catalogType,
                cartId,
                customer,
            )
            actions.toggleLoading(false)
            //TODO We can improve this later, for now we just display an error simply
            //errors when making the order
            if (createOrderError) setCreateOrderError(translateError(createOrderError))
            //otherwise open the card payment screen, by setting the payeeOrder to be paid for
            else if (success && payeeOrder) setCardPaymentPayeeOrder(payeeOrder)
        } 
        //validation errors
        else setCustomerErrors(errors)
        
    }
    //the values for the card payment modal
    const {totalPlusFeesXcd, reference, payerData={}} = cardPaymentPayeeOrder || {}
    const {id:payerId="", email="", phoneNumber="", address={}} = payerData
    return (
        <div>
            <div className="margin-auto  width-fit-content">
                <div className="card display-width-400px card-shadow">
                <div className="position-absolute"><img className="navLogo small" src={Logo} alt="SHOPDM PAY"/></div>
                    <div className="flex-center"><AvatarIcon name={payee.name} imageUrl={payee.logoImageUrlMed}/></div> 
                    <div className="h2 margin-bottom-05em text-align-center">{payee.name}</div>
                    <div className="text-align-center font-size-14px">{catalogName}</div>
                    <div className="amount big">EC $<AmountSmallAfterDecimal amount={cartTotalXcd}/></div>
                    <div className="margin-top-05em text-align-center color-grey font-size-14px">
                        <CustomerFeeCalculator payeeId={payeeId} totalXcd={cartTotalXcd} label="processing fee" left="+"/>
                    </div>
                    <div className={`text-align-center font-size-14px font-weight-600`}>For {countCartItems(cart)} items</div>
                    <PayerForm 
                        payerData={customer}
                        handleChange={handleChangeCustomerValue}
                        errors={customerErrors}
                    />
                    {
                        createOrderError ? 
                        <div className="errorText text-align-center">{createOrderError}</div>
                        :
                        ""
                    }
                    <button 
                        className="button gradient width-100pct margin-top-1em"
                        onClick={handleClickPayNow}
                    >
                        Pay Now
                    </button>
                </div>
                <div className="margin-top-1em card display-width-400px card-shadow font-size-14px color-grey">
                    <div>{payee.name} uses Shopdm Pay to accept card payments securely online.</div>
                    <div  className="margin-top-05em">Shopdm Pay is a product of Dom Software Ltd, a company registered in the Commonwealth of Dominica (No. 2019/C0074)</div>
                </div>
            </div>
            {
                cardPaymentPayeeOrder && cardPaymentPayeeOrder.ready ?
                <FacCardPaymentModal 
                    isOpen={Boolean(cardPaymentPayeeOrder)}
                    closeModal={() => setCardPaymentPayeeOrder(null)} 
                    onSuccess={() => actions.toggleLoading(true)}
                    amount2DecimalPlaces={totalPlusFeesXcd}
                    userId={user.id} //provide user id if payer is logged in
                    userName={`${user.firstName} ${user.lastName}`} //provider user name if payer is logged in
                    email={email}
                    phoneNumber={phoneNumber}
                    address={address}
                    payerId={payerId}
                    deviceId={device.id}
                    objectId={cardPaymentPayeeOrder.id}
                    objectType={PAYMENT_OBJECT_TYPE_PAYEE_ORDER}
                    reference={reference}
                    paymentProviderId={DEBIT_OR_CREDIT_CARD_ID}
                />
                :
                null
            }
        </div>
    )
}

const mapStateToProps = state => ({
    payees: state.payees,
    carts: state.carts,
    payeeProducts: state.payeeProducts,
    payeeCatalogs: state.payeeCatalogs,
    payeeOrders: state.payeeOrders,
    user: state.user,
    device: state.device
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PayeeOrderCheckout)