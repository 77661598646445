import { useMemo } from "react"
import styles from "./DotList.module.css"

const DotList = ({count=0, selectedIndex=null}) => {
    const dotArray= useMemo(() => new Array(count).fill(0), [count])
    return <div className={styles.container}>
                {dotArray.map((_, index) => (
                    <span key={index} className={`${styles.dot} ${selectedIndex === index ? styles.selected : ""}`} />
                ))}
            </div>
}

export default DotList